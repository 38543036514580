import Events from '../../../../assets/images/services-industry-events.png'
import NewsAndInsights from '../../../../assets/images/services-industry-news-and-insights.png'
import NewsInsights from '../../../../assets/images/services-news-and-insights.png'
import OurMarketplace from '../../../../assets/images/services-our-marketplace.png'
import IndustryEvents from '../../../../assets/images/services-recruiting-industry-events.png'
import SolutionMarketplace from '../../../../assets/images/services-solutions-marketplace.png'

export const ourServicesCards = [
  {
    id: 1,
    image: SolutionMarketplace,
    heading: <>Our Marketplace</>,
    class: 'md:w-[100px] md:h-[80px] lg:h-[100.16px] lg:w-[119.92px]'
  },
  {
    id: 2,
    image: NewsAndInsights,
    heading: <>News & insights</>,
    class: 'md:w-[133px] md:h-[80px] lg:h-[100.46px] lg:w-[153.95px]'
  },
  {
    id: 3,
    image: IndustryEvents,
    heading: <>Engaging Events</>,
    class: 'md:w-[88.3px] md:h-[82.25px] lg:h-[102px] lg:w-[108px]'
  }
]

export const cardsInsights = [
  {
    id: 1,
    image: OurMarketplace,
    heading: "The Marketplace: Where HR Tech Finds Its Perfect Match ",
    paragraph: "Discover and compare over 1,500 verified solutions in The Marketplace. We've curated the best recruitment technologies to help you automate, streamline, and hire qualified talent. Stop wasting time trying to find the right fit and let our AI-powered tools guide you to your ideal tech solution."
  },
  {
    id: 2,
    image: NewsInsights,
    heading: "News & Insights You Can Actually Use",
    paragraph: "Stay ahead of the curve with our daily briefs and weekly summaries covering the latest in HR Tech. We curate content that matters for growing companies and HR Tech leaders hungry for innovation. From in-depth buyer's guides to sharp market intelligence, consider us your personal HR Tech newsroom."
  },
  {
    id: 3,
    image: Events,
    heading: "Virtual Events That Spark Inspiration and Innovation",
    paragraph: "Step away from the daily grind to learn from industry experts and peers. Our engaging virtual events are designed to inspire, educate, and connect. Join us to expand your network, and gain fresh perspectives that make professional growth feel less like work and more like discovery."
  }
]

export const servicesMobileCards = [
  {
    id: 1,
    icon: SolutionMarketplace,
    heading: <>The Marketplace: Where HR Tech Finds Its Perfect Match</>,
    paragraph: "Discover and compare over 1,500 verified solutions in The Marketplace. We've curated the best recruitment technologies to help you automate, streamline, and hire qualified talent. Save valuable research time with our smart tools, and let Tally or our Fast Track matching service guide you to your ideal tech solution.",
    image: OurMarketplace
  },
  {
    id: 2,
    icon: NewsAndInsights,
    heading: <>News & Insights You Can Actually Use</>,
    paragraph: "Stay ahead of the curve with our daily briefs and weekly summaries covering the latest in HR Tech. We curate content that matters for growing companies and HR Tech leaders hungry for innovation. From in-depth buyer's guides to sharp market intelligence, consider us your personal HR Tech newsroom.",
    image: NewsInsights
  },
  {
    id: 3,
    icon: IndustryEvents,
    heading: <>Virtual Events That Spark Inspiration and Innovation</>,
    paragraph: "Step away from the daily grind to learn from industry experts and peers. Our engaging virtual events are designed to inspire, educate, and connect. Join us to expand your network, and gain fresh perspectives that make professional growth feel less like work and more like discovery.",
    image: Events
  }
]

export const servicesHeroCards = [
  {
    id: 1,
    title: 'Join',
    value: '100k+',
    description: <>talent professionals <br /> using our platform.</>
  },
  {
    id: 2,
    title: 'Discover',
    value: '1,500+',
    description: <>talent solutions, peer-reviewed <br /> and listed on The Marketplace.</>
  },
  {
    id: 3,
    title: 'Explore',
    value: '500+',
    description: <>curated industry events, <br /> discounted for members.</>
  },
]
