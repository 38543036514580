import React, { useState, useRef, useEffect } from 'react'

import Input from '@/components/atoms/inputs/Input'
import '../../../Pages/Solutions/multiselect.css'
import { useToken } from '@/components/atoms/forms/Form'
import { solutionOptions } from '@/components/atoms/constants/solutionOptions'

import { useForm, Link, Head, router } from '@inertiajs/react'

import Button from '@/components/atoms/buttons/Button'
import { putRequest } from '@/utils/axiosRequests'

import { toast } from 'react-toastify'
import CropModal from '../modals/CropModal'
import { useMobileDetect } from '@/utils/detectMobile'

type SolutionShowProps = {
  solution: schema.Solution
  current_user?: schema.User
  company: schema.Company
  formPath: string
  logo_url: string
  social_links: schema.SocialLink[]
  about_media: {
    media_url: string
    media_type: string
    file_name: string
  }
}

export default function SolutionForm({ ...props }: SolutionShowProps) {
  const { solution, social_links, about_media } = props
  const [dataChanged, setDataChanged] = useState(false)
  const [aboutMedia, setAboutMedia] = useState(null)
  const [fileName, setFileName] = useState(about_media?.file_name)
  const [isLoading, setIsLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [src, setSrc] = useState(null)

  const isMobile = useMobileDetect({ screenSize: 640 })
  const imageWidth = isMobile ? 300 : 600

  const handleFileChange = (file) => {
    setSrc(file)
    setOpenModal(true)
    setFileName(file.name)
  }

  const token = useToken()

  const { data, setData, errors } = useForm({
    size: solution.size,
    industry_type: solution.industry_type,
    website: solution.website,
    description: solution.description,
    name: solution.name,
    logo_url: solution.logo_url,
    social_links_attributes: social_links,
    title: solution.title,
  })

  const handleFileSelection = (file) => {
    if (!file) return
    const fileType = file.name.split('.').pop().toLowerCase();
    (fileType === 'mp4' || fileType === 'mov') ? handleMediaUpload(file) : handleFileChange(file)
  }

  const handleMediaUpload = (selected_file) => {
    setAboutMedia(selected_file)
    setFileName(selected_file.name)
  }

  const handleCropMediaUpload = (croppedFile) => {
    setAboutMedia(croppedFile)
    setOpenModal(false)
  }

  const reverseCompanySizeMap = {
    self_employed: 'self-employed',
    ten: '1-10 employees',
    fifty: '11-50 employees',
    two_hundred: '51-200 employees',
    five_hundred: '201-500 employees',
    one_thousand: '501-1,000 employees',
    five_thousand: '1,001-5,000 employees',
    ten_thousand: '5,001-10,000 employees',
    max: '10,001+ employees',
  }

  const companySizeMap = {
    'self-employed': 'self_employed',
    '1-10 employees': 'ten',
    '11-50 employees': 'fifty',
    '51-200 employees': 'two_hundred',
    '201-500 employees': 'five_hundred',
    '501-1,000 employees': 'one_thousand',
    '1,001-5,000 employees': 'five_thousand',
    '5,001-10,000 employees': 'ten_thousand',
    '10,001+ employees': 'max',
  }

  const formatTargetMarketValues = (field) => {
    if (field === 'size') {
      const value = data.size.map((size) => {
        return size
      })
      return value
    } else {
      const value = data.industry_type.map((industry) => {
        return industry
      })
      return value
    }
  }

  const transformSelectedOptions = (field, selectedOptions) => {
    if (field === 'size') {
      selectedOptions = selectedOptions.map((sizeOption) => {
        if (companySizeMap[sizeOption.label]) {
          return companySizeMap[sizeOption.label]
        }
        return sizeOption.value
      })
    }

    if (Array.isArray(selectedOptions) && selectedOptions.length > 0) {
      const labelsArray = selectedOptions
        .filter((option) => option.label !== undefined && option.label !== null)
        .map((option) => option.label)

      return labelsArray.length > 0 ? labelsArray : selectedOptions
    }

    return selectedOptions
  }

  const handleDropdownChange = (field, selectedOptions) => {
    const transformedOptions = transformSelectedOptions(field, selectedOptions)
    setData(field, transformedOptions)
    setDataChanged(true)
  }

  const transformedIndustryOptions = solutionOptions.industries.map(
    (industry) => ({
      label: industry.name,
      value: industry.value,
    })
  )

  const transformedCompanyOptions = solutionOptions.companySizes.map(
    (company) => ({
      label: company.name,
      value: company.value,
    })
  )

  const transformedSelectedSizeValues = formatTargetMarketValues('size').map(
    (value) => ({
      label: reverseCompanySizeMap[value],
      value: value,
    })
  )

  const transformedSelectedIndustryValues = formatTargetMarketValues(
    'industry'
  ).map((value) => ({
    label: value.label || value,
    value: value.label || value,
  }))

  const customValueRenderer = (selected, _options) => {
    const elements = document.querySelectorAll(
      '.dropdown-heading-value > span:first-child'
    )
    document.getElementsByClassName('')
    elements.forEach(function (element) {
      element.classList.add('flex')
    })
    return selected.length
      ? selected.map(({ label }) => (
          <div key={label} className='m-1 flex rounded-md bg-blue-tint-2 p-1'>
            {label || label.value}
          </div>
        ))
      : 'Please chose an option'
  }

  const validateSolutionOptions = () => {
    const sizes = formatTargetMarketValues('size')
    solutionOptions.companySizes = solutionOptions.companySizes.map(
      (companySize) => {
        if (sizes.includes(companySize.name)) {
          companySize.unavailable = true
        }
        return companySize
      }
    )

    const industries = formatTargetMarketValues('industry')
    solutionOptions.industries = solutionOptions.industries.map((industry) => {
      if (industries.includes(industry.name)) {
        industry.unavailable = true
      }
      return industry
    })
  }

  const handleBack = () => {
    // Wait for 1 seconds (1000 milliseconds) before redirecting
    setTimeout(() => {
      router.visit(`/solutions/${solution.slug}`) // Redirect to solution page after waiting
    }, 1000)
  }

  const handleSocialLinksChange = (index, value) => {
    setData((prevData) => {
      const updatedSocialLinks = prevData.social_links_attributes.map(
        (link, position) => {
          if (position === index) {
            return { ...link, url: value }
          }
          return link
        }
      )
      return { ...prevData, social_links_attributes: updatedSocialLinks }
    })
  }

  async function HandleSubmit() {
    try {
      setIsLoading(true)
      const formData = new FormData()
      for (const [key, value] of Object.entries(data)) {
        if (key === 'social_links_attributes') {
          data.social_links_attributes.forEach((social_link, index) => {
            if (social_link.id) {
              formData.append(`solution[social_links_attributes][${index}][id]`, social_link.id)
            }
              formData.append(`solution[social_links_attributes][${index}][name]`, social_link.name)
              formData.append(`solution[social_links_attributes][${index}][url]`, social_link.url)
          })
        } else {
          formData.append(`solution[${key}]`, value)
        }
      }
      aboutMedia && formData.append('solution[about_media]', aboutMedia)
      const response = await putRequest(`/solutions/${solution.id}`, formData, token)
      if (response.status === 200) {
        setData(response.data)
        setDataChanged(false)
        toast(
          <div>
            <span className='font-bold'>Success! </span>Your changes have been
            saved
          </div>,
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            className:
              'bg-teal-tint-2 text-md text-black p-4 md:p-6 md:rounded-xl md:w-[400px] md:right-[80px]',
            bodyClassName: 'p-0 min-w-[324px]',
          }
        )

        // Wait for 2 seconds (2000 milliseconds) before redirecting
        setTimeout(() => {
          router.visit(`/solutions/${solution.slug}`) // Redirect to solution page after waiting
        }, 2000)
      }
    } catch (error) {
      setIsLoading(true)
      toast(
        <div>
          <span className='font-bold'>Success! </span>
          {error}
        </div>,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          className:
            'bg-teal-tint-2 text-md text-black p-4 md:p-6 md:rounded-xl md:w-[400px] md:right-[80px]',
          bodyClassName: 'p-0 min-w-[324px]',
        }
      )
    }
    finally {
      setIsLoading(false)
    }
  }

  return (
    <div>
      <div className='mb-8 flex items-baseline'>
        <div>
          <h1 className='mb-5 text-xl font-bold text-black'>About</h1>
        </div>
        <div className='ml-auto flex gap-3'>
          <Button
            type='submit'
            size='md'
            variant={isLoading ? 'gray' : 'teal'}
            onClick={HandleSubmit}
            className={`${isLoading && 'text-gray-400'} flex h-12 w-20 items-center justify-center rounded-[12px] px-4 py-3`}
          >
             {isLoading ? 'Saving...' : 'Save'}
          </Button>
          <Button
            onClick={() => handleBack()}
            type='button'
            size='md'
            variant='gray'
            className='flex h-12 w-20 items-center justify-center rounded-[12px] px-4 py-3'
          >
            Cancel
          </Button>
        </div>
      </div>
      <div>
        {/* Overview Edit Container */}
        <div className='flex flex-wrap'>
          <div className='w-1/2 pr-2 max-md:w-[100%]'>
            <Input
              type='text'
              label='Solution name'
              labelHtmlFor='solution_name'
              id='solution_name'
              name='solution_name'
              value={data.name}
              onChange={(e) => setData('name', e.target.value)}
              containerClass='mb-7'
              errors={errors.name}
            />
          </div>
          <div className='w-1/2 max-md:w-[100%]'>
            <div className='flex flex-col gap-4'>
              <div className='flex gap-2'>
                <p>Website</p>
              </div>
              <Input
                type='text'
                labelHtmlFor='website'
                id='website'
                name='website'
                value={data.website}
                onChange={(e) => setData('website', e.target.value)}
                containerClass='mb-7'
                errors={errors.website}
              />
            </div>
          </div>
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 md:gap-x-2'>
          {data.social_links_attributes?.map((link, index) => (
            <div key={index} className='max-md:w-[100%]'>
              <div>
                <div className='flex gap-2'>
                  <p>{link.name} URL</p>
                </div>
                <Input
                  type='text'
                  labelHtmlFor={link.name}
                  id={link.name}
                  name={link.name}
                  value={link.url}
                  onChange={(e) =>
                    handleSocialLinksChange(index, e.target.value)
                  }
                  containerClass='mb-7'
                />
              </div>
            </div>
          ))}
        </div>
        <div className='mb-10 flex flex-col gap-[16px]'>
          <div
            className='w-full md:flex md:gap-5'
            onLoad={validateSolutionOptions}
          >
            <div className='max-w-38rem w-1/2 max-md:w-full'>
              <label className='font-Manrope text-base font-medium leading-relaxed'>
                Title
              </label>
              <Input
                type='text'
                labelHtmlFor='title'
                id='title'
                name='title'
                value={data.title}
                onChange={(e) => setData('title', e.target.value)}
                containerClass='mb-7'
                errors={errors.title}
                maxLength={50}
              />
            </div>
            {solution.paid_solution && (
              <div className='max-w-38rem w-1/2 max-md:w-full'>
              <div className='font-Manrope text-base font-medium leading-relaxed'>
                Upload File (Img/Video)
              </div>
              <label
              htmlFor='media-input'
              className='cursor-pointer block border border-gray-300 rounded-lg px-3 py-[1.1rem] text-sm font-medium text-gray-700 hover:bg-gray-50'
            >
              {fileName ?? 'Choose a file'}
            </label>
              <input
                id='media-input'
                type='file'
                accept='.jpg,.jpeg,.png,.gif,.mp4,.mov'
                className='hidden'
                onChange={(e) => handleFileSelection(e.target.files[0])}
              />
              <CropModal
                openModal={openModal}
                setOpenModal={setOpenModal}
                src={src}
                handleFileChange={handleCropMediaUpload}
                previewUrlSetter={() => {}}
                maxImageSize={2 * 1024 * 1024}
                imageWidth={imageWidth}
              />
            </div>
            )}
          </div>
        </div>
        <label htmlFor='description' className='mb-3 block text-lg text-black'>
          About
        </label>
        <textarea
          className='mb-0 h-[383px] w-full rounded-xl border border-dark-gray px-5 py-4 text-black focus:border-dark-gray focus:outline-none focus:ring-0 md:h-[221px] lg:h-[194px]'
          htmlFor='description'
          value={data.description}
          onChange={(e) => {
            setData('description', e.target.value)
            setDataChanged(true)
          }}
        />
      </div>
    </div>
  )
}
