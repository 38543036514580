import { useState, Suspense, useEffect } from 'react'
import { Popover } from '@headlessui/react'
import BetapartnersSection from '@/components/organisms/betaPartnersSection/BetapartnersSection'
import FooterSection from '@/components/organisms/footerSection/FooterSection'
import MarketplaceHeroSection from '@/components/organisms/heroSection/MarketplaceHeroSection'
import HighlightsSection from '@/components/organisms/highlightsSection/HighlightsSection'
import IntroSection from '@/components/organisms/introSection/IntroSection'
import Navbar from '@/components/organisms/layouts/Navbar'
import PodcastsSection from '@/components/organisms/podcastsSection/PodcastsSection'
import HomepageSolutionSection from '@/components/organisms/solutionsSection/HomepageSolutionSection'
import Articles from '@/components/organisms/article/Articles';
import IndustryEvents from '@/components/organisms/Events/industryEvents'
import usePageRedirection from '@/utils/usePageRedirection'
import LeadGenerationModal from '@/components/molecules/modals/LeadGenerationModal'
import useCurrentUser from '@/hooks/useCurrentUser'
import { useToken } from '@/components/atoms/forms/Form'
import axios from 'axios'
import { router } from '@inertiajs/react'
import PageTitle from '@/components/atoms/widgets/PageTitle'
import Meta from '@/components/atoms/widgets/Meta'
import bg from 'app/assets/images/Grey-Wave.svg'
import TallyBot from '@/components/molecules/tally/TallyBot'
import Waitlist from '@/components/molecules/waitlist/Waitlist'
import { useMobileDetect } from '@/utils/detectMobile'

type HomepageProps = {
  solutions: schema.Solution[]
  categories: schema.Category[]
  events: schema.Event[]
  showNewsletterModal: boolean
  articles: schema.Articles[]
  article_categories: schema.ArticleCategories[]
  should_logout: boolean
};
const Homepage = ({ solutions, articles, article_categories, categories, events, showNewsletterModal = false, should_logout = false}: HomepageProps) => {

  usePageRedirection()
  const [openSubscribeModal, setOpenSubscribeModal] = useState(showNewsletterModal)
  const currentUser = useCurrentUser()
  const token = useToken()
  const isMobile = useMobileDetect()

  useEffect(() => {
    const handleLogout = async () => {
      try {
        await axios.delete('/users/sign_out', {
          headers: {
            'X-CSRF-Token': token,
          },
        });
      } catch (error) {
        console.error('Logout failed', error);
        throw error;
      }
    };

    const performLogout = async () => {
      if (should_logout && currentUser) {
        try {
          await handleLogout();
          localStorage.setItem('rmc_logged_in', 'false');
          localStorage.setItem('rmc_logged_in_user_id', '');
          router.visit('/customer/login',{
            data: {
              fallbackUrl: '/',
            }
          });
        } catch (error) {
          console.error('Failed to logout and redirect', error);
        }
      }
    };

    const handleUserSession = () => {
      if (currentUser) {
        localStorage.setItem('rmc_logged_in', 'true');
        localStorage.setItem('rmc_logged_in_user_id', currentUser?.id);
      }
    };

    performLogout();
    handleUserSession();
  }, [should_logout, currentUser, token, router]);


  return (
    !(should_logout && currentUser) &&
    <>
      <PageTitle title='Talivity | Your Talent Acquisition Destination ' />
      <Meta 
        title='Talivity | Your Talent Acquisition Destination'
        description='Discover the future of hiring with the latest industry news, events and podcasts. Access top solutions and stay ahead in talent acquisition.'
      />
        <Popover className="flex flex-col z-50">
          {({ open }) => <Navbar open={open} />}
        </Popover>
        <div
          className="sm:bg-contain bg-no-repeat bg-center pt-[52px] sm:pt-80px]"
          style={{
            backgroundImage: `url(${bg}), linear-gradient(180deg, #FFF 0%, #EAECEE 80%, #FFF 100%)`,
            backgroundPosition: `center ${isMobile ? '580px' : '395px'}, center`,
          }}
        >
          <MarketplaceHeroSection />
          <TallyBot />
          <Waitlist />
        </div>
      <div className='2xl:px-40 xl:px-10 md:px-6 my-[64px] w-[329px] sm:w-auto mx-auto sm:mx-0'>
        <IntroSection />
      </div>
      <div className="2xl:px-40 xl:px-10 md:px-6 md:pt-[4rem] sm:px-3 sm:mt-0 mt-20">
        <HighlightsSection />
      </div>
      { articles?.length > 0 &&
        <div className="2xl:px-40 xl:px-10 md:px-6 mt-[102px] md:mt-[148px] sm:px-3">
          <Articles articles={articles} article_categories={article_categories}/>
        </div>
      }
      <div className="sm:px-5 md:px-0 2xl:px-40 xl:px-10 mt-[102px] md:mt-[148px]">
        <HomepageSolutionSection categories={categories} solutions={solutions} />
      </div>
      <div className='md:mt-[7rem] pt-10 lg:mt-6 lg:pt-0'>
        <BetapartnersSection />
      </div>
      <div className='lg:pt-[124px] md:pt-[7rem] w-[329px] sm:w-auto mx-auto sm:mx-0 sm:mt-0 mt-20'>
        <IndustryEvents industryEvents={events} />
      </div>
      <div className='lg:pt-[124px] md:pt-[7rem] mt-[122px] sm:pt-20 lg:mt-10'>
        <PodcastsSection />
      </div>
      <div className='md:pt-[7rem] mt-10 lg:pt-[124px] lg:mt-0 sm:mt-20'>
        <FooterSection />
      </div>
      <Suspense fallback={<div></div>}>
        <LeadGenerationModal
          openModal={openSubscribeModal}
          setOpenModal={setOpenSubscribeModal}
          formType={'newsletter'}
          currentUser={currentUser}
        />
      </Suspense>
    </>
  )
}

export default Homepage
