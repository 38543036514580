import { lazy } from 'react';
const Slider = lazy(() => import('@ant-design/react-slick'));
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {
  CustomDots,
  NextArrow,
  PrevArrow,
} from '@components/atoms/widgets/Carousel'
import EventCard from '@/components/atoms/eventCard/EventCard'
import HybridLink from '@/components/atoms/widgets/HybridLink'
import { INDUSTRY_EVENTS } from '../../../utils/productionEventLinks'
import chavironBlue from '../../../../assets/images/chevron-blue.svg'
import useSSR from '@/hooks/useSSR';

type IndustryEventsProps = {
  industryEvents: {
    title: string
    description: string
    website: string
    start_date: string
    end_date: string
    city: string
    state: string
    image_url: string
    page_url: string
  }[]
}

export default function IndustryEvents({ ...props }: IndustryEventsProps) {

  const isSSR = useSSR();

  const { industryEvents } = props

  const settings = {
    dots: industryEvents.length > 1,
    infinite: industryEvents.length > 1,
    slidesToShow: 0.999,
    slidesToScroll: 1,
    prevArrow: industryEvents.length > 1 ? <PrevArrow customClasses={'absolute left-[82%] sm:left-[75%] xl:left-[79.2%] lg:left-[80%] md:left-[79%] xl:top-[99%] lg:top-[99%] md:top-[99%] top-[100%] mt-[1rem] lg:mt-[1rem]'} /> : null,
    nextArrow: industryEvents.length > 1 ? <NextArrow customClasses={'absolute left-[92%] sm:left-[83%] xl:left-[82.5%] lg:left-[84%] md:left-[83%] xl:top-[99%] lg:top-[99%] md:top-[99%] top-[100%] mt-[1rem] lg:mt-[1rem]'} /> : null,
    appendDots: (dots) => <CustomDots dots={dots} customClasses={'mt-[1rem] lg:mt-[1rem] absolute left-[-43%] sm:left-[-39%] xl:left-[-47.7%] lg:left-[-44%] md:left-[-44%] top-[99%]'} />,
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 200,
    cssEase: 'linear',
    centerPadding: '100px'
  }

  const extract_date = (date) => {
    const day = new Date(date).getDate()
    return `${day < 10 ? '0' : ''}${day}`
  }

  const extract_month = (date) => {
    return new Date(date).toLocaleString('en-US', { month: 'long' })
  }

  const extract_year = (date) => {
    return `${new Date(date).getFullYear()}`;
  }

  return (
    <>
      {isSSR ? (
        <div className="flex justify-center">Loading...</div>
      ) : (
        <div className='mx-auto xl:px-10 md:px-6 sm:px-3'>
          <div className='sm:px-4 md:px-10 lg:px-36 xl:px-[177px] max-w-[1320px] mx-auto'>
            <div className='flex sm:flex-row flex-col justify-between sm:items-center'>
              <h1 className='sm:text-[1.75rem] xl:text-[2.375rem] text-[38px] font-bold'> Events </h1>
              <div className="flex gap-1 items-center pr-[1rem] xl:pr-[3px] mt-[3px] sm:mt-0">
                <HybridLink href={INDUSTRY_EVENTS} isExternal={true} className="text-[17px] sm:text-sm sm:font-medium cursor-pointer text-[#11D0B0] whitespace-nowrap">View All Events</HybridLink>
                <img className="h-1.5 w-1.5 sm:h-[0.6rem] sm:w-[0.6rem] sm:mt-[0.2rem] sm:ml-[0.2rem] self-center" src={chavironBlue} alt="arrow" loading='lazy' />
              </div>
            </div>
          </div>
          <div className='sm:pl-4 md:pl-10 lg:pl-36 xl:pl-[8.5rem] max-w-[1260px] mx-auto'>
            <Slider {...settings}>
              {industryEvents.map((event, index) => (
                <div key={index} >
                  <EventCard
                    title={event.title}
                    description={event.description}
                    website={event.website}
                    start_date={extract_date(event.start_date)}
                    end_date={extract_date(event.end_date)}
                    month={extract_month(event.start_date)}
                    year={extract_year(event.end_date)}
                    city={event.city}
                    state={event.state}
                    image_url={event.image_url}
                    page_url={event.page_url}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      )}
    </>
  );
}
