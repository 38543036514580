import React, { useEffect, useState } from 'react'
import AboutUsAdDesktop from '../../../../assets/images/NewsletterDesktop.svg'
import AboutUsAdMobile from '../../../../assets/images/NewsletterMobile.svg'
import LeadGenerationModal from '@/components/molecules/modals/LeadGenerationModal'
import useCurrentUser from '@/hooks/useCurrentUser'

const AccountCreationSection = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const [openSubscribeModal, setOpenSubscribeModal] = useState(false)
  const currentUser = useCurrentUser()

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleSignUp = () => {
    setOpenSubscribeModal(true)
  }
  return (
    <>
      <LeadGenerationModal
        openModal={openSubscribeModal}
        setOpenModal={setOpenSubscribeModal}
        formType={'newsletter'}
        currentUser={currentUser}
      />
      <div className='text-center text-white xl:max-w-[972px] mx-auto pt-[100px] md:px-0 px-[1.438rem]'>
        {!isMobile ? (
          <img
            src={AboutUsAdDesktop}
            alt='About Us Ad'
            className='mx-auto lg:h-[21.813rem] md:h-[15.563rem] h-[36.875rem] lg:w-[60.75rem] md:w-[768px] hover:cursor-pointer'
            onClick={handleSignUp}
          />
          ) : (
            <img
              src={AboutUsAdMobile}
              alt='About Us Ad'
              className='mx-auto object-contain hover:cursor-pointer'
              onClick={handleSignUp}
            />
          )
        }
      </div>
    </>
  )
}

export default AccountCreationSection
