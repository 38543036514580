import Cliquify from 'app/assets/images/betaPartners/beta-Cliquify-logo.png'
import Cronofy from 'app/assets/images/betaPartners/beta-Cronofy.png'
import Dalia from 'app/assets/images/betaPartners/beta-Dalia.png'
import Fairygodbosss from 'app/assets/images/betaPartners/beta-Fairygodboss.png'
import inlpoi from 'app/assets/images/betaPartners/beta-inploi.png'
import Jamyr from 'app/assets/images/betaPartners/beta-Jamyr.png'
import Jobsync from 'app/assets/images/betaPartners/beta-JobSync.png'
import Nordcard from 'app/assets/images/betaPartners/beta-Nordcards.png'
import Onwardsearch from 'app/assets/images/betaPartners/beta-Onward-Search.png'
import Powertofly from 'app/assets/images/betaPartners/beta-Power-to-fly.png'
import Recruitics from 'app/assets/images/betaPartners/beta-Recruitics.png'
import Survale from 'app/assets/images/betaPartners/beta-Survale.png'
import Vibo from 'app/assets/images/betaPartners/beta-Vibo.png'
import Vprople from 'app/assets/images/betaPartners/beta-vprople.png'
import Marquee from 'react-fast-marquee'

const images = [
  Cliquify,
  Cronofy,
  Dalia,
  Fairygodbosss,
  inlpoi,
  Jamyr,
  Jobsync,
  Nordcard,
  Onwardsearch,
  Powertofly,
  Recruitics,
  Survale,
  Vibo,
  Vprople,
]

const BetaPartners = () => {
  return (
    <Marquee pauseOnHover={false} autoFill={true}>
      {images.map((img, index) => (
          <img
            key={index}
            src={img} 
            alt='beta partners logo' 
            className='w-[150px] px-2 sm:w-[230px] md:px-4 lg:px-6' 
            />
      ))}
    </Marquee>
  )
}

export default BetaPartners
