import React, { useEffect, useState } from 'react'

import Button from '@components/atoms/buttons/Button'

import Chevron from '../../../../assets/images/chevron-right.svg'

import decodeHtmlEntities from '@/utils/decodeHtmlEntities'
import { redirectToUrl } from '@/utils/helperFunctions'
import minifyString from '@/utils/minifyString'

type EventCardProps = {
  title: string
  description: string
  website: string
  start_date: string
  end_date: string
  month: string
  year: string
  city: string
  state: string
  image_url: string
  page_url: string
}

export default function EventCard({ ...props }: EventCardProps) {
  const { title, description, website = '', start_date, end_date, city, state, image_url, month, year, page_url } = props
  const [isTab, setIsTab] = useState(window.innerWidth < 1024)

  useEffect(() => {
    const handleResize = () => setIsTab(window.innerWidth < 1200)
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [isTab])

  return (
    <div className='w-full flex flex-row mx-auto mt-[1.5rem] lg:mt-[3.6rem]'>
      <div className='flex items-center justify-center w-[95%] lg:w-1/2 cursor-pointer' onClick={() => redirectToUrl(page_url)}>
        <img src={image_url} alt={title} className='rounded-3xl min-w-[310px] sm:min-w-0 lg:w-[550px] size-auto object-cover' />
      </div>
      <div className='hidden lg:flex flex-col gap-4 justify-center pb-4 pl-12 md:pr-[1.9rem] lg:pr-[8.5rem] xl:pr-[165px] w-1/2'>
        <p className='lg:text-4xl lg:leading-[35px] xl:text-6xl xl:leading-[49.5px] font-bold text-3xl'>
          {minifyString(decodeHtmlEntities(title), description ? 30 : 60, description ? 30 : 60, false)}
         {description ? (title.length > 30 && (
            <a href={website} className='text-[#11D0B0] cursor-pointer' rel='nofollow'>....</a>
          )) : (title.length > 60 && (
            <a href={website} className='text-[#11D0B0] cursor-pointer' rel='nofollow'>....</a>
          ))}
        </p>
        <span className='flex justify-start text-sm lg:text-sm xl:text-lg font-semibold w-full'>
          <p>{month} {start_date} - {end_date}, {year}</p>
          {city && <p className='pl-3'>{city}, {state}</p>}
        </span>
        <div className='text-sm lg:text-sm xl:text-lg font-semibold'>
          <p className='text-[19px]'>
            {minifyString(description, 65, 65)}
            {description.length > 65 && (
              <a href={website} className='pl-3 text-[#11D0B0] text-[18px]' rel='nofollow'>
                Learn More <img src={Chevron} alt='Learn more icon' className='inline-block' style={{ display: 'inline-block' }} />
              </a>
            )}
          </p>
        </div>
        <Button variant='secondary' size='sm' className='mt-[1.5rem]' onClick={() => redirectToUrl(page_url)} trackBtnClick={true}>
          View Event
        </Button>
      </div>
    </div>
  )
}
