import { useState } from 'react'

import { Disclosure } from '@headlessui/react'
import { IoChevronUp } from 'react-icons/io5'

type EditFaqsAccordionProps = {
  faqs: schema.Faqs[]
  onFaqChange: (updatedFaqs: schema.Faqs[]) => void
}

const EditFaqsAccordion: React.FC<EditFaqsAccordionProps> = ({
  faqs,
  onFaqChange,
}) => {
  const [localFaqs, setLocalFaqs] = useState(faqs)

  const handleInputChange = (index: number, key: string, value: any) => {
    const updatedFaqs = [...localFaqs]
    updatedFaqs[index] = { ...updatedFaqs[index], [key]: value }
    setLocalFaqs(updatedFaqs)
    onFaqChange(updatedFaqs)
  }

  return (
    <div className='mx-auto mt-4 w-full divide-y divide-gray-border rounded-2xl border border-gray-border bg-white'>
      {localFaqs.map((item, index) => (
        <Disclosure key={index}>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`flex w-full items-center justify-between bg-mystic px-4 py-[8px]
                  ${index === 0 ? (faqs.length === 1 && !open ? 'rounded-2xl' : 'rounded-t-2xl') : index === faqs.length - 1 && !open ? 'rounded-b-2xl' : ''}`}
              >
                <input
                  type='text'
                  className='w-full break-words rounded-xl border border-black bg-white p-[17px] text-left text-[16px] font-normal text-outer-space'
                  value={item.question}
                  onChange={(e) =>
                    handleInputChange(index, 'question', e.target.value)
                  }
                  onKeyDown={(e) => e.stopPropagation()}
                  maxLength={120}
                />
                <div className='mx-4 flex items-center'>
                  <label className='relative inline-flex cursor-pointer items-center'>
                    <input
                      type='checkbox'
                      className='peer sr-only'
                      checked={item.is_active}
                      onChange={(e) =>
                        handleInputChange(index, 'is_active', e.target.checked)
                      }
                    />
                    <div className="peer h-4 w-8 rounded-full bg-gray-400 after:absolute after:left-[-3px] after:top-[-2px] after:size-5 after:rounded-full after:bg-white after:drop-shadow-md after:transition-all after:content-[''] peer-checked:bg-teal peer-checked:after:translate-x-full peer-checked:after:border-white peer-checked:after:drop-shadow-md" />
                  </label>
                </div>
                <IoChevronUp
                  className={`${!open && 'rotate-180'} size-5 transition-transform duration-300`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className='px-4 py-[8px]'>
                <textarea
                  className='w-full break-words rounded-xl border border-black bg-white p-[17px] text-left text-[16px] font-normal text-outer-space'
                  value={String(item.answer)}
                  onChange={(e) =>
                    handleInputChange(index, 'answer', e.target.value)
                  }
                  onKeyDown={(e) => e.stopPropagation()}
                  rows={2}
                  maxLength={560}
                />
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}
    </div>
  )
}

export default EditFaqsAccordion
