import { Popover } from '@headlessui/react'

import Navbar from '@/components/organisms/layouts/Navbar'
import HorizontalMarginsWrapper from '@components/atoms/wrappers/HorizontalMarginsWrapper'
import AboutUsHeroSection from '@/components/organisms/heroSection/AboutUsHeroSection'
import OurServicesSection from '@/components/organisms/ourServices/OurServicesSection'
import FooterSection from '@/components/organisms/footerSection/FooterSection'
import {
  AboutUsBetaPartners,
  AboutUsCenterDiv,
  MeetMyTeam,
} from '@/components/organisms/AboutUs'
import MissionAccountSection from '@/components/organisms/missionAccountSection/MissionAccountSection'
import { useMobileDetect } from '../../utils/detectMobile'
import useCurrentUser from '@/hooks/useCurrentUser'
import Meta from '@/components/atoms/widgets/Meta'
import PageTitle from '@/components/atoms/widgets/PageTitle'
import Bg from '../../../assets/images/greyblend.svg'

const outerMargins = 'pt-[4.875rem] md:pt-[7.625rem]'

const AboutUs = () => {
  const isMobile = useMobileDetect()

  const currentUser = useCurrentUser();

  return (
    <>
      <PageTitle title='About the Talivity Marketplace | Explore Top TA Tech Tools' />
      <Meta
        title='About the Talivity Marketplace | Explore Top TA Tech Tools'
        description='Learn about Talivity Marketplace, your go-to hub for the top talent acquisition tech solutions, formerly RecruitmentMarketing.com.'
      />
      <div
        className={`flex w-full bg-fade-gray-gradient flex-col ${isMobile ? '' : 'bg-contain'} py-16 bg-no-repeat 3xl:bg-cover`}
        style={{
          backgroundImage: `url(${Bg}), linear-gradient(160.11deg, #FFFFFF 38.99%, #EAECEE 86.72%)`,
          backgroundPosition: `${isMobile ? 'left 263px' : 'center 180px'}`
        }}
      >
        <Popover className="flex flex-col z-50">
          {({ open }) => <Navbar open={open} />}
        </Popover>
        <AboutUsHeroSection />
      </div>
      <AboutUsBetaPartners />
      <HorizontalMarginsWrapper>
        <OurServicesSection />
      </HorizontalMarginsWrapper>
      <MissionAccountSection />
      { ( currentUser?.newsletter_subscribed ) ? null : (
          <div>
            <AboutUsCenterDiv />
          </div>
        )
      }
      <HorizontalMarginsWrapper>
        <MeetMyTeam />
      </HorizontalMarginsWrapper>
      <div className={`${outerMargins}`}>
        <FooterSection />
      </div>
    </>
  )
}

export default AboutUs
