import BetaPartners from '@/components/molecules/Marqee/BetaPartners'

const AboutUsBetaPartners = () => {
  return (
    <div className='flex flex-col justify-center py-[33px] lg:py-[45px] gap-[20px] lg:gap-[30px] sm:px-20'>
      <h3 className='text-center font-semibold text-[23px]' style={{ color: 'rgba(0, 0, 0, 0.28)' }}>Our Trusted Partners</h3>
      <BetaPartners/>
    </div>
  )
}

export default AboutUsBetaPartners
