type RequestModalHeadingProps = {
  solutionName: string
  source: string
  showRequestAQuote?: boolean
  isClaim?: boolean
  solutionClaimed?: boolean
  paidSolution?: boolean
  contactUsBtn?: boolean
}

type GetInTouchHeadingProps = {
  solutionName: string
}

const GetInTouchHeading = ({ solutionName }: GetInTouchHeadingProps) => {
  return (
    <p className='mb-3'>
      Talivity will get in touch with {solutionName} regarding your request.
    </p>
  )
}

const RequestModalHeading = ({ solutionName,
  source,
  contactUsBtn,
  showRequestAQuote,
  isClaim,
  solutionClaimed,
  paidSolution
}: RequestModalHeadingProps) => {
  return (
    (source === 'demo' || contactUsBtn ?
      <GetInTouchHeading solutionName={solutionName} />
      :
      (!showRequestAQuote
        ?
        <p className='mb-6'>
          {isClaim ?
            'Enter your details below to submit this claim.'
            : 'Enter your details below to learn more.'}
        </p>
        : solutionClaimed
          ? paidSolution
            ? <p className='mb-6'>Enter your details below and someone from {solutionName} will be in touch</p>
            : <p className='mb-6'>Talivity will get in touch with {solutionName} regarding your request and may follow up with you to assist with your solution search.</p>
          : <p className='mb-6'>Talivity will get in touch with {solutionName} regarding your request and may follow up with you to assist with your solution search.</p>
      )
    )
  )
}

export default RequestModalHeading
