import React, { useState } from 'react';
import Input from '@/components/atoms/inputs/Input';
import { useToken } from '@/components/atoms/forms/Form';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useForm, router } from '@inertiajs/react';
import Button from '@/components/atoms/buttons/Button';
import ErrorMessage from '@/components/atoms/Toast/ErrorMessage';
import { defaultToastOptions } from '@/utils/commonToast';

type SolutionShowProps = {
  solution: schema.Solution;
  current_user?: schema.User;
};

export default function PricingEditForm({ ...props }: SolutionShowProps) {
  const { solution } = props;
  const [dataChanged, setDataChanged] = useState(false);
  const token = useToken();

  const { data, setData, errors } = useForm({
    isSolutionFree: solution.is_solution_free,
    offerFreeTrial: solution.offer_free_trial,
    startingCost: solution.starting_cost,
    costingFrequency: solution.costing_frequency,
    pricingDetails: solution.pricing_details,
  });

  const setTrialValue = (offerFreeTrial) => {
    return data.offerFreeTrial == null ? false : offerFreeTrial;
  };

  const handleBack = () => {
    // Wait for 1 seconds (1000 milliseconds) before redirecting
    setTimeout(() => {
      router.visit(`/solutions/${solution.slug}`); // Redirect to solution page after waiting
    }, 1000);
  };

  async function handleSubmit(e) {
      if (!data.isSolutionFree && data.startingCost <= 0) {
        toast(
          <ErrorMessage message={'The Starting Cost must be greater than zero for paid solutions.'} />,
          defaultToastOptions('red-200')
        )
        return
      }
    try {
      const response = await axios.put(
        `/solutions/${solution.id}`,
        {
          solution: {
            costing_frequency:
              data.isSolutionFree === true ? 'monthly' : data.costingFrequency,
            is_solution_free: data.isSolutionFree,
            offer_free_trial:
              data.isSolutionFree === true ? null : data.offerFreeTrial,
            pricing_details: data.pricingDetails,
            starting_cost: data.startingCost,
          },
        },
        {
          headers: {
            'X-CSRF-Token': token,
            Accept: 'application/json',
          },
        }
      );
      if (response.status === 200) {
        setDataChanged(false);
        toast(
          <div>
            <span className="font-bold">Success! </span>Your changes have been
            saved
          </div>,
          {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            className:
              'bg-teal-tint-2 text-md text-black p-4 md:p-6 md:rounded-xl md:w-[400px] md:right-[80px]',
            bodyClassName: 'p-0 min-w-[324px]',
          }
        );

        // Wait for 2 seconds (2000 milliseconds) before redirecting
        setTimeout(() => {
          router.visit(`/solutions/${solution.slug}`); // Redirect to solution page after waiting
        }, 2000);
      }
    } catch (error) {
      toast(
        <div>
          <span className="font-bold">Success! </span>
          {error}
        </div>,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          className:
            'bg-teal-tint-2 text-md text-black p-4 md:p-6 md:rounded-xl md:w-[400px] md:right-[80px]',
          bodyClassName: 'p-0 min-w-[324px]',
        }
      );
    }
  }

  return (
    <div>
      <div className="my-4 flex items-baseline">
        <div>
          <h1 className="mb-5 text-lg font-bold text-black sm:text-xl">
            Pricing
          </h1>
        </div>
        <div className="ml-auto hidden gap-3 sm:flex">
          <Button
            type="submit"
            size="md"
            variant="teal"
            className="flex h-12 w-20 items-center justify-center rounded-[12px] px-4 py-3"
            onClick={handleSubmit}
          >
            Save
          </Button>
          <Button
            onClick={() => handleBack()}
            type="button"
            size="md"
            variant="gray"
            className="flex h-12 w-20 items-center justify-center rounded-[12px] px-4 py-3"
          >
            Cancel
          </Button>
        </div>
      </div>
      <div>
        {/* Pricing Edit Container */}
        <div className="flex flex-wrap">
          <div className="flex w-1/2 flex-col gap-2 pr-2 max-md:w-[100%]">
            <div className="flex flex-col gap-1">
              <p>Is your solution free or paid?</p>
              <div className="flex gap-1">
                <input
                  type="checkbox"
                  checked={data.isSolutionFree}
                  onChange={(e) => setData('isSolutionFree', e.target.checked)}
                  className="mb-3 h-6 w-6 rounded-xl"
                />
                <p>Free</p>
              </div>
              <div className="flex gap-1">
                <input
                  type="checkbox"
                  checked={!data.isSolutionFree}
                  onChange={(e) => setData('isSolutionFree', !e.target.checked)}
                  className="mb-3 h-6 w-6 rounded-xl"
                />
                <p>Paid</p>
              </div>
            </div>
            {!data.isSolutionFree ? (
              <>
                <div className="flex flex-col gap-1">
                  <p>Do you offer a free trial?</p>
                  <div className="flex gap-1">
                    <input
                      type="checkbox"
                      checked={setTrialValue(data.offerFreeTrial)}
                      onChange={(e) =>
                        setData('offerFreeTrial', e.target.checked)
                      }
                      className="mb-3 h-6 w-6 rounded-xl"
                    />
                    <p>Yes</p>
                  </div>
                  <div className="flex gap-1">
                    <input
                      type="checkbox"
                      checked={setTrialValue(!data.offerFreeTrial)}
                      onChange={(e) =>
                        setData('offerFreeTrial', !e.target.checked)
                      }
                      className="mb-3 h-6 w-6 rounded-xl"
                    />
                    <p>No</p>
                  </div>
                </div>
                <div className="flex flex-col gap-2 sm:flex-row">
                  <div className="flex w-full flex-col gap-1">
                    <p>Starting Cost</p>
                    <Input
                      labelHtmlFor="Staring_Cost"
                      value={`$ ${Math.floor(data.startingCost) || 0}`}
                      errors={errors?.name}
                      onChange={(e) => {
                        setData(
                          'startingCost',
                          Number(e.target.value.split(' ')[1]) || 0
                        );
                      }}
                    />
                  </div>
                  <div className="flex w-full flex-col gap-1">
                    <p>Frequency:</p>
                    <select
                      id="costing_frequency_dropdown"
                      value={data.costingFrequency}
                      onChange={(e) => {
                        setData('costingFrequency', e.target.value);
                      }}
                      className="h-[58px] w-full rounded-xl border-[1px] border-dark"
                    >
                      <option value="yearly">Year</option>
                      <option value="monthly">Month</option>
                    </select>
                  </div>
                </div>
              </>
            ) : null}
            <div className="flex gap-2">
              <div className="flex w-full flex-col gap-1">
                <p>Pricing Details:</p>
                <textarea
                  className="mb-0 h-[383px] w-full rounded-xl border border-dark-gray px-5 py-4 text-black focus:border-dark-gray focus:outline-none focus:ring-0 md:h-[221px] lg:h-[194px]"
                  htmlFor="pricing_details"
                  id="text_pricing_details"
                  value={data.pricingDetails}
                  onChange={(e) => {
                    if (e.target.value.length <= 1000)
                      setData('pricingDetails', e.target.value);
                  }}
                />
                <div className="flex justify-between">
                  <label
                    htmlFor="text_pricing_details"
                    className="text-xs text-[#8A8DA0]"
                  >
                    Max 1000 characters
                  </label>
                  <span
                    htmlFor="text_pricing_details"
                    className="text-xs text-[#8A8DA0]"
                  >
                    {data.pricingDetails?.length}
                  </span>
                </div>
              </div>
            </div>
            <div className="ml-auto flex w-full flex-col gap-3 sm:hidden">
              <Button
                type="submit"
                size="md"
                variant="teal"
                className="flex h-12 w-full items-center justify-center rounded-[12px] px-4 py-3"
                onClick={handleSubmit}
              >
                Save
              </Button>
              <Button
                onClick={() => handleBack()}
                type="button"
                size="md"
                variant="gray"
                className="flex h-12 w-full items-center justify-center rounded-[12px] px-4 py-3"
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
