import marketplaceHero from '../../../../assets/images/about-hero.png'

const AboutUsHeroSection = () => {
  return (
    <>
      <div className='mx-[1.5rem] md:px-[4rem] lg:px-[6rem] xl:px-[9rem] max-w-[1440px] self-center'>
        <div className='flex flex-col sm:flex-row gap-[4rem] xl:gap-[7.3rem] items-center'>
          <div className='sm:w-[50%] flex flex-col md:items-start'>
            <p 
              className='text-cyan font-extrabold text-[44px] md:text-[60px] lg:text-[58px] pt-[70px] md:pt-[55px] lg:pt-[65px]'
            >
              About Talivity
            </p>
            <p className='text-[1rem] text-black sm:text-[1.3rem] md:text-[0.76rem] lg:text-[0.85rem] xl:text-[1rem] pt-[20px] sm:pt-[33px] lg:pt-[17px] md-xl:w-[32.5rem] w-[22rem] sm:w-[20rem]'>
              People power business. To acquire top talent, industry leaders must stay ahead of the market and embrace the latest technology and innovations in our space. And that’s where Talivity comes in. Across our community, marketplace, and events, we help you to find the right solutions and answers to help you hire the best of the best.
              <br /><br />
              By combining cutting-edge AI technology, industry expertise, and a vast network of solutions, we equip leaders with the tools, insights, and connections they need to excel at every stage of their career.
            </p>
          </div>
          <img src={marketplaceHero} alt='about-us-hero-image' className='sm:mt-14 sm:w-[43%] sm:h-auto' />
        </div>
      </div>
    </>
  )
}

export default AboutUsHeroSection
