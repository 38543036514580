import { useRef, lazy } from "react";
import { useState, useEffect } from "react";
const Slider = lazy(() => import('@ant-design/react-slick'));
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import chavironLeft from '../../../../assets/images/chevron-left.svg'
import chavironBlue from '../../../../assets/images/chevron-blue.svg'
import { CustomDots, NextArrow, PrevArrow } from '@components/atoms/widgets/Carousel';
import HomepageSolutionCard from "@/components/molecules/solution/HomepageSolutionCard";
import HybridLink from "@/components/atoms/widgets/HybridLink";
import axios from "axios";
import window from 'global'
import useSSR from "@/hooks/useSSR";

import './styles.css'

type HomepageSolutionSectionProps = {
  categories: schema.Category[];
  solutions: schema.Solution[];
}

const HomepageSolutionSection = ({ ...props }: HomepageSolutionSectionProps) => {

  const isSSR = useSSR();

  const { categories, solutions } = props;
  const [solutionsToShow, setSolutionsToShow] = useState(solutions);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 650);
  const [isTab, setIsTab] = useState(window.innerWidth < 1024);

  const [activeFilter, setActiveFilter] = useState(categories ? categories[0] : '');

  const containerRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 650);
      setIsTab(window.innerWidth <= 1024);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);

  const filterSolutionsByCategory = async (cat) => {
    const response = await axios.get('homepage/filter_by_category', {
      params: {
        category: cat
      }
    });
    const responseData = response?.data?.solutions;
    setSolutionsToShow(responseData);
  }

  useEffect(() => {
    const slickTrack = document.querySelector('.slick-track')
  
    if (!isMobile && slickTrack) {
      const styleElement = document.createElement('style')
  
      if (solutionsToShow.length <= 3) {
        styleElement.innerHTML = '.slick-track { transform: none !important; }'
      } else {
        styleElement.innerHTML = ''
      }
  
      document.head.appendChild(styleElement)
  
      return () => {
        if (styleElement) {
          document.head.removeChild(styleElement)
        }
      };
    }
  }, [solutionsToShow])

  const marketplaceURL = '/marketplace';

  const settings = {
    variableWidth: isMobile ? true : false,
    autoPlay: false,
    infinite: solutionsToShow.length > 3,
    dots: true,
    speed: 200,
    slidesToShow: isMobile ? 1 : isTab ? 2 : 3,
    slidesToScroll: 1,
    prevArrow: <PrevArrow customClasses={'mt-0 z-10 sm:z-0 absolute left-[76%] xl:left-[82.8%] lg:left-[84%] md:left-[82%] sm:left-[91%] xl:top-[100%] sm:w-[60px] sm:h-[60px] lg:top-[100%] md:top-[99%] top-[101%]'} />,
    nextArrow: <NextArrow customClasses={'mt-0 absolute left-[84%] xl:left-[85.7%] lg:left-[88%] md:left-[87%] xl:top-[100%] sm:left-[96%] sm:w-[60px] sm:h-[60px] lg:top-[100%] md:top-[99%] top-[101%]'} />,
    appendDots: (dots) => <CustomDots dots={dots} customClasses={'absolute left-[-26%] xl:left-[-34%] lg:left-[-39%] md:left-[-38%] top-[100%]'} />,
    className: "center",
    centerMode: true,
    centerPadding: !isMobile ? isTab ? '80px' : '11.5%' : '0px',
  };

  return (
    isSSR ? (
      <div className="flex justify-center">Loading...</div>
    ) : (
      <>
        <div className='w-[329px] sm:w-auto mx-auto flex flex-col xl:mx-auto max-w-[1320px] md:px-20 lg:px-44 xl:px-44'>
          <h1 className='sm:text-[1.75rem] xl:text-[2.375rem] text-[38px] font-extrabold -tracking-[0.5px] mb-[8px] md:mb-[27px]'>The Marketplace</h1>
          <p className='text-[18px] sm:text-[0.7rem] md:text-lg font-semibold mb-[13px] md:mb-[30px] lg:w-[80%]'>Discover leading HR technology solutions, assess ratings, compare solutions, and equip your team with the ideal tech stack for hiring success.</p>
          <div className="flex w-full flex-col-reverse gap-[19px] md:flex-row flex-start justify-between">
            <div className='flex flex-start justify-between w-[100%] overflow-scroll scrollbar-none rounded-lg category-section-container'>
              <img src={chavironLeft} alt="prev" loading='lazy' className="block xl:hidden h-[15px] w-[15px] mr-4 sm:mt-[0.35rem] mt-[0.1rem]  ml-[-2.3px] sm:ml-0" onClick={() => {
                containerRef.current.scrollLeft -= 200;
              }} />
              <div className="flex w-full gap-0 sm:gap-4 overflow-x-auto scrollbar-none" ref={containerRef}>
                <div className="xl:hidden flex gradient-left"></div>
                {categories?.map((category, index) => (
                  <span className={`${category === activeFilter ? 'bg-[#f3f3f3] rounded-lg font-extrabold' : ''} flex items-center px-3 py-1 mb-[29px] sm:md-[50px] md:mb-[46px]`} onClick={() => {
                    setActiveFilter(category)
                  }}>
                    <p className={`cursor-pointer text-[12px] sm:text-[0.75rem] text-black-100 hover:font-extrabold whitespace-nowrap`} onClick={() => filterSolutionsByCategory(category)}>{category}</p>
                  </span>
                ))}
              </div>
              <div className="xl:hidden flex gradient-right"></div>
              <img src={chavironLeft} alt="next" loading='lazy' className="block xl:hidden h-[15px] w-[15px] rotate-180 sm:mt-[0.35rem] mt-[0.1rem] ml-4" onClick={() => {
                containerRef.current.scrollLeft += 200;
              }} />
            </div>
            <div className="hidden md:flex gap-1 items-center pr-[1rem] xl:pr-[9px] mt-[3px] sm:mt-0 mb-[46px]">
              <HybridLink href={marketplaceURL} className="text-xs sm:text-sm sm:font-medium cursor-pointer text-[#11D0B0] whitespace-nowrap">Visit The Marketplace</HybridLink>
              <img className="h-1.5 w-1.5 sm:h-[0.6rem] sm:w-[0.6rem] sm:mt-[0.2rem] sm:ml-[0.2rem] self-center" src={chavironBlue} alt="arrow" loading='lazy' />
            </div>
            <div className="md:hidden flex gap-1 items-center pr-[1rem] xl:pr-[9px] mt-[3px] sm:mt-0">
              <HybridLink href={marketplaceURL} className="text-[17px] sm:text-sm sm:font-medium cursor-pointer text-[#11D0B0] whitespace-nowrap">View The Marketplace</HybridLink>
              <img className="h-1.5 w-1.5 sm:h-[0.6rem] sm:w-[0.6rem] sm:mt-[0.2rem] sm:ml-[0.2rem] self-center" src={chavironBlue} alt="arrow" loading='lazy' />
            </div>
          </div>
        </div>
      {solutionsToShow && <div className="solution-section-container max-w-[1260px] xl:mx-auto sm:px-4 xl:mx-0">
        <div className="gradient-left"></div>
        <div className="gradient-right"></div>
        <Slider {...settings}>
          {solutionsToShow.map((solution, index) => {
            return (
              <div className="mx-[14px] sm:mx-0" key={index}>
                <HomepageSolutionCard
                  solution={solution}
                  activeFilter={activeFilter}
                />
              </div>
            )
          })
          }
        </Slider>
      </div>}
    </>)
  );
};

export default HomepageSolutionSection;
