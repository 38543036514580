import { Head } from '@inertiajs/react'

interface MetaProps {
  title?: string
  description?: string
}

const Meta = ({
  title = "Talivity | Your Talent Acquisition Destination",
  description = "Discover the future of hiring with the latest industry news, events and podcasts. Access top solutions and stay ahead in talent acquisition.",
}: MetaProps) => {
  return (
    <Head>
      <meta name="description" content={description} />
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="twitter:title" content={title} />
      <meta property="twitter:description" content={description} />
    </Head>
  )
}

export default Meta
