import React from 'react'

import ReCAPTCHA from 'react-google-recaptcha'

type ReCaptchaProps = {
  setRecaptchaToken: (value: string) => void
}

const ReCaptcha: ReCaptchaProps = ({ setRecaptchaToken }) => {
  const handleVerify = (response) => {
    if (response) {
      setRecaptchaToken(response)
    }
  }

  return (
    <div>
      <ReCAPTCHA
        sitekey={import.meta.env.VITE_RECAPTCHA_SITE_KEY}
        onChange={handleVerify}
      />
    </div>
  )
}

export default ReCaptcha
