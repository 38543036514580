import React from 'react'

type MarketplaceCardProps = {
  heading: string
  description: string
}

const MarketplaceCard = ({ heading, description } : MarketplaceCardProps) => {
  return (
    <div className='col-span-2 sm:col-span-1 flex flex-col justify-start items-center lg:w-[195px] lg:h-[130px] bg-white rounded-xl border border-blue-tint-2 p-5'>
      <p className='text-xl font-bold text-dark-blue'>{heading}</p>
      <p className='text-xs text-center text-dark-blue'>{description}</p>
    </div>
  )
}
 
export default MarketplaceCard