import * as React from 'react'

import HybridLink from '@/components/atoms/widgets/HybridLink'

import emptyLogo from '../../../../assets/images/lg-logoPlaceHolder.svg'
import solutionLogoPlaceholder from '../../../../assets/images/solution-logo-placeholder.svg'
import whiteCloseIcon from '../../../../assets/images/white-close-icon-small.svg'
import Button from '../../atoms/buttons/Button'

type CompareSolutionTabProps = {
  solutionsToCompare: schema.Solution[]
  setSolutionsToCompare: React.Dispatch<
    React.SetStateAction<schema.Solution[]>
  >
}

export function CompareSolutionTab({
  solutionsToCompare,
  setSolutionsToCompare,
}: CompareSolutionTabProps) {
  const removeSolution = (solution) => {
    const updatedSolutionsToCompare = solutionsToCompare.filter((s) => {
      if (solution.name === s.name) return false
      return true
    })
    setSolutionsToCompare(updatedSolutionsToCompare)
  }
  const getSlugsOfSolutions = (solutions) => {
    const ids = solutions.map((s) => {
      return s.slug
    })
    return ids
  }

  return (
    <div className='flex flex-col items-center justify-between gap-6 px-4 pt-5 pb-10 sm:flex-row sm:px-10 sm:py-5 md:px-20'>
      <div className='flex w-full items-center justify-between sm:w-auto'>
        <p>Compare Solutions</p>
        <p
          className='ml-[10px] cursor-pointer text-xs underline sm:hidden'
          onClick={() => setSolutionsToCompare([])}
        >
          Remove All
        </p>
      </div>
      <div className='flex items-center gap-[10px]'>
        {Array.from({ length: 4 }, (_, i) => (
          <div key={i}>
            {solutionsToCompare[i] ? (
              <div className='size-[72px] sm:size-[44px]'>
                <img
                  src={
                    solutionsToCompare[i].logo_url || solutionLogoPlaceholder
                  }
                  alt='solution logo'
                  className={'size-full rounded-full'}
                />
                <div
                  className='relative top-[-75px] right-[-50px] flex size-[20px] cursor-pointer items-center justify-center rounded-xl bg-dark-blue sm:top-[-50px] sm:right-[-30px]'
                  onClick={() => removeSolution(solutionsToCompare[i])}
                >
                  <img src={whiteCloseIcon} alt='close icon' />
                </div>
              </div>
            ) : (
              <img src={emptyLogo} alt='empty logo' className='sm:size-[44px]' />
            )}
          </div>
        ))}
        <p
          className='ml-[10px] hidden cursor-pointer text-xs underline sm:block'
          onClick={() => setSolutionsToCompare([])}
        >
          Remove All
        </p>
      </div>
      {solutionsToCompare.length > 1 ? (
        <HybridLink
          href={`/solutions/compare_solution/${getSlugsOfSolutions(solutionsToCompare)}`}
          className='w-full text-sm sm:w-auto'
          isExternal={false}
        >
          <Button
            variant={solutionsToCompare?.length === 1 ? 'tertiary' : 'primary'}
            className='p-3'
            disabled={solutionsToCompare?.length <= 1}
            trackBtnClick={true}
          >
            Compare Now
          </Button>
        </HybridLink>
      ) : (
        <Button
          variant={solutionsToCompare?.length === 1 ? 'disable' : 'primary'}
          className='p-3'
          disabled={solutionsToCompare?.length <= 1}
          trackBtnClick={true}
        >
          Compare Now
        </Button>
      )}
    </div>
  )
}
