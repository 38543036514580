import { useState } from 'react'

import { ErrorMessage } from '@hookform/error-message'
import { router } from '@inertiajs/react'
import editPencilBlackIcon from 'app/assets/images/edit-pencil.svg'
import { toast } from 'react-toastify'

import Button from '@/components/atoms/buttons/Button'
import { useToken } from '@/components/atoms/forms/Form'
import SuccessMessage from '@/components/atoms/Toast/SuccessMessage'
import EmptySection from '@/components/atoms/widgets/EmptySection'
import EditFaqsAccordion from '@/components/molecules/accordions/EditFaqsAccordion'
import FaqsAccordion from '@/components/molecules/accordions/FaqsAccordion'
import { putRequestWithoutFormData } from '@/utils/axiosRequests'
import { defaultToastOptions } from '@/utils/commonToast'
import { TIMEOUT_INTERVAL } from '@/utils/constants'

type FaqSectionProps = {
  faqs: schema.Faqs[]
  isClaimed: boolean
  solution: schema.Solution
  isSolutionOwner: boolean
}

const FaqSection: React.FC<FaqSectionProps> = ({
  faqs,
  isClaimed,
  solution,
  isSolutionOwner,
}) => {
  const token = useToken()

  const [editFaq, setEditFaq] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [updatedFaqs, setUpdatedFaqs] = useState(faqs)

  const allowEdit = isSolutionOwner && isClaimed

  const handleEditClick = () => {
    setEditFaq(true)
  }

  const FilteredFaqs = (faqs) => {
    return faqs.filter((faq) => faq.is_active && String(faq.answer).trim())
  }

  const handleFaqChange = (newFaqs: schema.Faqs[]) => {
    setUpdatedFaqs(newFaqs)
  }

  const showEmptySection =
    !editFaq && FilteredFaqs(faqs).length === 0 && isSolutionOwner

  const handleSubmit = async () => {
    const faqs_data = {
      solution: {
        faqs_attributes: updatedFaqs,
      },
    }
    const solutionUpdateURL = `/solutions/${solution.slug}`
    setIsLoading(true)

    try {
      const response = await putRequestWithoutFormData(
        solutionUpdateURL,
        faqs_data,
        token
      )
      if (response.status == 200) {
        toast(
          <SuccessMessage
            message={'Your changes have been saved successfully!'}
          />,
          defaultToastOptions('teal-tint-2')
        )
        setTimeout(() => router.visit(solutionUpdateURL), TIMEOUT_INTERVAL)
        setEditFaq(false)
      } else {
        toast(
          <ErrorMessage
            message={'Something went wrong! Please try again.'}
            name={''}
          />,
          defaultToastOptions('red-200')
        )
      }
    } catch (error) {
      toast(
        <ErrorMessage
          message={'Something went wrong! Please try again.'}
          name={''}
        />,
        defaultToastOptions('red-200')
      )
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className='mt-[2rem] sm:mt-[6rem]'>
      <div className='flex items-center justify-between'>
        <div>
          <h1 className='text-lg font-bold sm:text-xl'>FAQ</h1>
        </div>
        {allowEdit && (
          <div>
            {!editFaq ? (
              <div className='rounded-full bg-white p-2'>
                <img
                  src={editPencilBlackIcon}
                  alt='pencil icon'
                  onClick={handleEditClick}
                  className='w-[35px] stroke-white'
                  style={{ cursor: 'pointer' }}
                  loading='lazy'
                />
              </div>
            ) : (
              <div className='ml-auto flex gap-2'>
                <Button
                  type='submit'
                  size='md'
                  variant='teal'
                  className='flex h-12 w-20 items-center justify-center rounded-[12px] px-4 py-3'
                  onClick={handleSubmit}
                  isLoading={isLoading}
                >
                  {isLoading ? 'Saving...' : 'Save'}
                </Button>
                <Button
                  type='button'
                  size='md'
                  variant='gray'
                  className='flex h-12 w-20 items-center justify-center rounded-[12px] px-4 py-3'
                  onClick={() => setEditFaq(false)}
                  isLoading={isLoading}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
      {!editFaq ? (
        showEmptySection ? (
          <EmptySection
            heading='No FAQs Yet'
            paragraph='Click the pencil icon to answer frequently asked questions on your profile.'
          />
        ) : (
          <FaqsAccordion faqs={FilteredFaqs(faqs)} />
        )
      ) : (
        <EditFaqsAccordion faqs={updatedFaqs} onFaqChange={handleFaqChange} />
      )}
    </div>
  )
}

export default FaqSection
