import { cardsInsights } from '@/components/atoms/constants/OurServicesData'

function ServicesCardInsights({ activeCardId }) {
  const activeCard = cardsInsights.find(card => card.id === activeCardId)

  return (
    <div className="w-full flex md:mt-[80px] lg:mt-[116px] justify-center gap-[2rem] xl:gap-[6rem]">
      <div className="flex flex-col w-1/2">
        <h1 className="text-dark-blue font-Manrope font-extrabold md:text-[22.57px] lg:text-[32px] pb-4">
          {activeCard.heading}
        </h1>
        <p className="xl:text-[17px] md:text-[12px]">
          {activeCard.paragraph}
        </p>
      </div>
      <div className="w-1/2 flex justify-end self-start">
        <img src={activeCard.image} alt={activeCard.heading} className='w-full' loading='lazy' />
      </div>
    </div>
  );
}

export default ServicesCardInsights
