import React, { useState, useEffect, useRef } from 'react';

import {
  sortOptions,
  sortOptionsMap,
  sortOptionsReverseMap,
} from '@components/atoms/constants/solutionOptions';
import Layout from '@components/organisms/layouts/Layout';
import Modal from '@components/atoms/modals/Modal';
import { SolutionCard } from '@/components/molecules/solution/SolutionCard';
import { StartGeneratingQualityLeadsCTA } from '@/components/molecules/leads/StartGeneratingQualityLeadsCTA';
import Chevron from 'app/assets/images/chevron-left.svg';
import axios from 'axios';
import clsx from 'clsx';
import debounce from 'lodash.debounce';
import ReactPaginate from 'react-paginate';

import { ReactComponent as BackArrow } from '../../../assets/images/back-arrow.svg';
import { ReactComponent as CloseIcon } from '../../../assets/images/close-icon-black.svg';
import { ReactComponent as FilterIcon } from '../../../assets/images/filter-icon.svg';
import SearchedSolutionsAndCategories from '../Solutions/SearchedSolutionsAndCategories';

import Button from '@/components/atoms/buttons/Button';
import { CompareSolutionTab } from '@/components/molecules/compareSolution/CompareSolutionTab';
import FilterSidebar, { FilterSubhead } from '@/components/atoms/filters/FilterSidebar';
import useLocalStorage from '@/components/atoms/hooks/useLocalStorage';
import Input from '@/components/atoms/inputs/Input';
import Meta from '@/components/atoms/widgets/Meta';
import { SortMenu } from '@/components/molecules/filterAndSort/SortMenu';
import HandleSearch from '@/utils/HandleSearch';
import { mixpanel } from '@/utils/mixpanel';

export default function CategoryShow({category, categorySolutions}) {
  const [searchQuery, setSearchQuery] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [solutionsToCompare, setSolutionsToCompare] = useState([]);
  const [enterPressed, setEnterPressed] = useState(false);
  const [filter, setFilter] = useLocalStorage('categories-filters', {
    claimed: false,
    sort: 'is_featured',
    rating_gteq: null,
  });

  const [solutionsToShow, setSolutionsToShow] = useState([]);
  const [searchedSolutions, setSearchedSolutions] = useState([]);
  const [currentPagination, setcurrentPagination] = useState([]);
  const [page, setPage] = useState(1);
  const isInitialRender = useRef(true);

  const handleReload = async () => {
    const requestData = {
      q: {
        name_i_cont: '',
        claims_status_matches_any: filter?.claimed ? 'approved' : '',
        rating_gteq: filter?.rating_gteq || null,
        searchQuery: searchQuery || null
      },
      order_by: filter?.sort,
    };

    if (page !== 1) {
      Object.assign(requestData, { page: page });
    }

    const response = await axios.get(
      `/categories/${category.id}/filter_category_solutions`,
      {
        headers: {
          Accept: 'application/json',
        },
        params: requestData,
      }
    );
    setSolutionsToShow(response?.data?.solutions);
    setcurrentPagination(response.data.pagination);
    setPage(response.data.pagination.current_page);
  };
  const [searchResultsOpen, setSearchResultsOpen] = useState(false);
  const prevSearchQuery = useRef();

  const handlePopState = () => {
    setSearchQuery('');
  };

  useEffect(() => {
    const handleBeforeUnload = () => setSearchQuery('');

    window.addEventListener('beforeunload', handleBeforeUnload);
    window.addEventListener('popstate', handlePopState);

    const debouncedReload = debounce(() => {
      handleReload();
    }, 300);

    if (prevSearchQuery.current !== searchQuery) {
      if (searchQuery.length >= 2 || searchQuery.length === 0) {
        debouncedReload();
      }
    } else {
      handleReload();
    }

    prevSearchQuery.current = searchQuery;

    return () => {
      window.removeEventListener('popstate', handlePopState);
      window.removeEventListener('beforeunload', handleBeforeUnload);

      debouncedReload.cancel();
    };
  }, [filter, enterPressed ? searchQuery : null, enterPressed]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    } else {
      handleReload();
    }
  }, [page]);

  const handleBack = () => {
    window.history.back();
  };

  function clearFilters() {
    setFilter({
      claimed: false,
      sort: 'is_featured',
      rating_gteq: null,
    });
  }

  const handleMixpanelSearchEvent = () => {
    mixpanel.track('Search', {
      'Search Term': searchQuery,
      Category: [category?.name],
      Claimed: filter?.claimed,
      'Sort By': sortOptionsReverseMap[filter?.sort],
      'Entry Point': 'Category Search',
    });
  };

  const handleSearchedClose = (e) => {
    if (e === '') {
      setSearchResultsOpen(false);
      setSearchedSolutions([]);
      setSearchQuery('');
      setEnterPressed(false);
    }
  };

  const handleChange = (e) => {
    if (e.target.value.trim() === '') {
      setSearchResultsOpen(false);
      setSearchedSolutions([]);
      setSearchQuery('');
      setEnterPressed(false);
    } else {
      setSearchResultsOpen(true);
      HandleSearch({
        event: e,
        allSolutions: categorySolutions,
        allCategories: [],
        searchTerm: searchQuery,
        setSearchTerm: setSearchQuery,
        setSolutionSearchResults: setSearchedSolutions,
        setCategorySearchResults: null,
      });

      setSearchResultsOpen(searchQuery !== '');
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    setEnterPressed(true);
  };

  return (
    <Layout type='menu' noPadding={true}>
      <Meta
        title={
          category?.name
            ? `${category.name} Recruitment Marketing Solutions - Find Top Vendors on the Talivity Marketplace`
            : `Recruitment Marketing Solutions - Find Top Vendors on the Talivity Marketplace`
        }
        description={`Find top ${category?.name} recruitment marketing solutions on the Talivity Marketplace. Streamline your hiring process and find the best solutions for your business needs.`}
      />
      <div>
        {/* Header Container */}
        <div className='bg-light-gray px-4 py-5 sm:px-10 sm:pb-8 xl:px-20 xl:py-8'>
          <div
            className='mb-5 flex cursor-pointer gap-2 xl:hidden'
            onClick={handleBack}
          >
            <BackArrow />
            <p className='text-lg font-semibold leading-[27px] text-black'>
              Back
            </p>
          </div>
          <div className='text-black sm:gap-8 md:flex md:justify-between xl:gap-10'>
            <div className='flex flex-col justify-center'>
              <div
                className='mb-5 hidden cursor-pointer gap-2 xl:flex'
                onClick={handleBack}
              >
                <BackArrow />
                <p className='text-lg font-semibold leading-[27px] text-black'>
                  Back
                </p>
              </div>
              <h1 className='mb-5 text-5xl font-bold leading-[42.5px] sm:mb-6 sm:text-6xl xl:text-7xl'>
                {category.name}
              </h1>
              {/* TODO: Add category description */}
              <p
                className={clsx(
                  'mb-5 w-full text-lg leading-[27px] sm:text-md sm:leading-[24px]'
                )}
              >
                {category?.description}
              </p>
            </div>
          </div>
        </div>
        <div className='mb-2 flex items-center justify-between px-4 py-6 sm:mx-10 md:mb-8 md:border-b md:pt-[28px] xl:mx-0 xl:px-[80px]'>
          <p className='self-center text-md font-bold uppercase sm:text-xl'>
            Explore Solutions ({currentPagination?.total_count})
          </p>
          <div className='hidden items-center gap-4 md:relative md:flex'>
            <SortMenu
              options={Object.entries(sortOptionsMap).map(([label, value]) => ({
                label,
                value,
              }))}
              onChange={(value) => {
                setFilter({
                  ...filter,
                  sort: value,
                });
              }}
              selected={filter.sort as string}
            />
          </div>
        </div>
        {/* Mobile Input */}
        <div className='flex gap-2 px-4 sm:mx-10 sm:pt-[28px] md:hidden'>
          <form
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <Input
              type='text'
              placeholder={`Search ${category.name} solutions`}
              search={true}
              value={searchQuery}
              onChange={(e) => handleChange(e)}
              className='w-full pr-10'
            >
              {searchedSolutions.length > 0 &&
                searchResultsOpen &&
                !enterPressed && (
                  <SearchedSolutionsAndCategories
                    wrapperClassName={clsx(
                      'relative left-0 top-[0px] z-50',
                      solutionsToShow.length > 1 && 'max-h-[250px]'
                    )}
                    searchedSolutions={searchedSolutions}
                    searchedCategories={[]}
                    searchTerm={searchQuery}
                    onClose={() => {
                      setSearchResultsOpen(false);
                    }}
                    open={searchResultsOpen}
                  />
                )}
            </Input>
          </form>
          <div className='flex gap-4 md:hidden'>
            <div className='relative'>
              <button
                className={clsx(
                  'flex h-[60px] w-[60px] items-center justify-center gap-2 rounded-xl border md:hidden',
                  filter.sort ? 'border-blue-tint-2 bg-blue-tint-2' : 'border-medium-gray'
                )}
                onClick={() => {
                  setOpenModal(true);
                }}
              >
                <FilterIcon />
              </button>
            </div>
          </div>
        </div>
        {/* Desktop Version */}
        <div className='flex gap-8 px-4 py-6 sm:p-10 xl:px-[80px] xl:pt-[28px]'>
          <div className='hidden shrink-0 grow-0  md:flex  md:flex-col'>
            <div className='hidden shrink-0  grow-0 pb-6  md:flex  md:flex-col'>
              <form
                onSubmit={(e) => {
                  handleSubmit(e);
                }}
              >
                <Input
                  type='text'
                  placeholder={`Search ${category.name} solutions`}
                  search={true}
                  value={searchQuery}
                  onChange={(e) => handleChange(e)}
                  className='w-full pr-10'
                >
                  {searchedSolutions.length > 0 &&
                    searchResultsOpen &&
                    !enterPressed && (
                      <SearchedSolutionsAndCategories
                        wrapperClassName={clsx(
                          'relative left-0 top-[0px] z-50',
                          solutionsToShow.length > 5 && 'max-h-[250px]'
                        )}
                        searchedSolutions={searchedSolutions}
                        searchedCategories={[]}
                        searchTerm={searchQuery}
                        onClose={() => {
                          setSearchResultsOpen(false);
                        }}
                        open={searchResultsOpen}
                      />
                    )}
                </Input>
              </form>
              {searchQuery && (
                <CloseIcon
                  className='relative right-4 bottom-7 h-5 w-5 cursor-pointer sm:left-[280px]'
                  onClick={(e) => {
                    setSearchQuery('');
                    handleSearchedClose('');
                  }}
                />
              )}
            </div>
            <div className='hidden min-w-[315px] gap-4 sm:flex'>
              <FilterSidebar
                onClose={() => setOpenModal(false)}
                filter={filter}
                setFilter={setFilter}
                selectedCategories={filter.selectedCategories}
                hideCategoryFilter={true}
              />
            </div>
          </div>
          {/* Solutions Container */}
          <div className='flex flex-col gap-4'>
            {solutionsToShow?.length > 0 && (
              <div
                className='grid grid-cols-1 gap-6'
                onClick={() => handleMixpanelSearchEvent()}
              >
                {solutionsToShow.map(
                  (solution) => (
                    <SolutionCard
                      solution={solution}
                      hideEditButton={true}
                      className={solutionsToCompare ? ' ' : 'h-full'}
                      key={solution.name}
                      entryPoint='Category Show Page'
                      solutionsToCompare={solutionsToCompare}
                      setSolutionsToCompare={setSolutionsToCompare}
                    />
                  )
                )}
              </div>
            )}
            {solutionsToShow?.length > 0 && (
              <div className='flex justify-center'>
                <ReactPaginate
                  pageCount={currentPagination?.total_pages}
                  pageRangeDisplayed={3}
                  marginPagesDisplayed={1}
                  previousLabel={
                    <img src={Chevron} alt='previous page arrow' className='' />
                  }
                  nextLabel={
                    <img
                      src={Chevron}
                      alt='next page arrow'
                      className='rotate-180'
                    />
                  }
                  breakLabel='...'
                  onPageChange={(event) => {
                    setPage(event.selected + 1);
                  }}
                  forcePage={page - 1}
                  containerClassName='flex justify-between items-center self-stretch'
                  pageClassName='flex py-3 px-5 justify-center items-center gap-2 bg-white rounded-xl'
                  activeClassName='!bg-dark-blue !text-white'
                  previousClassName='mr-6'
                  nextClassName='ml-6'
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {solutionsToCompare?.length > 0 && (
        <div className='sticky bottom-0 z-[10] bg-[#ECEDF1] shadow-sticky-bar'>
          <CompareSolutionTab
            solutionsToCompare={solutionsToCompare}
            setSolutionsToCompare={setSolutionsToCompare}
          />
        </div>
      )}
      <StartGeneratingQualityLeadsCTA />
      <Modal
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
        closeStyle='top-8 right-4'
        backdropStyle='pt-[136px] sm:pt-0'
        className='!fixed bottom-0 w-screen'
      >
        <div className='max-h-screen overflow-y-auto rounded-t-3xl bg-white px-4 py-8'>
          <h3 className='mb-7 text-2xl font-bold leading-tight'>
            Filter and Sort
          </h3>
          <div>
            <FilterSubhead>Sort By:</FilterSubhead>
            <div className='mb-10 mt-4 flex flex-col gap-6'>
              {sortOptions.map((sortOption) => (
                <div
                  className='sort-container flex gap-[10px]'
                  key={sortOption}
                >
                  <Input
                    type='radio'
                    className={clsx(
                      'filter-radio relative h-[24px] w-[24px] cursor-pointer rounded-full border !border-medium-gray p-[4px] text-white  focus:ring-0 focus:ring-white',
                      filter.sort !== sortOptionsMap[sortOption] && ''
                    )}
                    onChange={(e) => {
                      setFilter({
                        ...filter,
                        sort: sortOptionsMap[sortOption],
                      });
                    }}
                    checked={filter.sort === sortOptionsMap[sortOption]}
                  />
                  <p className='ml-2 text-lg leading-[27px]'>{sortOption}</p>
                </div>
              ))}
            </div>
          </div>
          <FilterSidebar
            onClose={() => setOpenModal(false)}
            filter={filter}
            setFilter={setFilter}
            selectedCategories={filter.selectedCategories}
            isModal={openModal}
            hideCategoryFilter={true}
          />
          <div className='mt-4 flex flex-col gap-4' id='filterSortActions'>
            <Button
              size='md'
              variant='primary'
              onClick={() => setOpenModal(false)}
            >
              Apply
            </Button>
            <Button
              size='md'
              variant='tertiary'
              onClick={() => {
                clearFilters();
                setOpenModal(false);
              }}
            >
              Clear Filters
            </Button>
          </div>
        </div>
      </Modal>
    </Layout>
  );
}
