import HybridLink from '@/components/atoms/widgets/HybridLink'
import logo from 'app/assets/images/talivity-logo.png'

const TalivityLogo = ({ rootUrl, rootUrlExternal }) => {
  return (
    <HybridLink
      href={rootUrl}
      isExternal={rootUrlExternal}
      className='flex items-center sm:pr-[12px]'
    >
      <img
        src={logo}
        alt={'Talivity Logo'}
        className={'cursor-pointer h-[34px] sm:h-[48.822px] sm:w-[83px]'}
      />
    </HybridLink>
  )
}

export default TalivityLogo
