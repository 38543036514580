import clsx from "clsx"

function ServicesCardValue({ card }) {
  const valueClass = clsx(
    'font-extrabold',
    'text-center text-[48px]',
    'md:text-[33.463px] md:leading-[37px] md:tracking-[-0.976px]',
    'lg:text-[48px] lg:leading-[52px] lg:tracking-[-1.399px]'
  )
  return (
    <p
      className={clsx(
        valueClass,
        'text-dark-teal'
      )}
    >
      {card.value}
    </p>
  )
}

export default ServicesCardValue