import React, { useEffect, useState } from 'react';
import Modal from '../../atoms/modals/Modal';
import Button from '../../atoms/buttons/Button';
import Input from '../../atoms/inputs/Input';
import Form, { useToken } from '@/components/atoms/forms/Form';
import ReCaptcha from '@/components/atoms/widgets/Recaptcha';
import useWindowSize from '../../atoms/hooks/useWindowSize';
import { useForm } from '@inertiajs/react';
import axios from 'axios';
import { mixpanel } from '@/utils/mixpanel';
import { event } from '@fullstory/browser';
import useCurrentUserId from '@/hooks/useCurrentUserId';
import { SuccessModal } from '../../molecules/modals/ClaimSuccessModal';
import subscribeToNewsLetter from '@/utils/newsletterSubscription';
import ModalChecks from '../../molecules/modals/ModalChecks';
import RequestModalHeading from '../../atoms/modals/RequestModalHeading';
import { isEmailValid , isWorkEmail } from '../../../utils/emailValidation';
import { useTracking } from 'react-tracking'

type UnauthorizedClaimModalProps = {
  openUnauthorizedFormModal: any;
  setOpenUnauthorizedFormModal: any;
  solutionID: number;
  solutionName: string;
  isClaim: boolean;
  redirectURL: string;
  setStatus: React.Dispatch<React.SetStateAction<string>>;
  solutionClaimed: boolean;
  solutionOwner?: schema.User;
  showRequestAQuote?: boolean;
  contactUsBtn?: boolean;
  setContactUsBtn?: React.Dispatch<React.SetStateAction<boolean>>;
  logoUrl?: string;
  company?: schema.Company;
  setShowRequestAQuote?: React.Dispatch<React.SetStateAction<boolean>>;
  paid_solution: boolean;
};

export default function UnauthorizedClaimModal({
  ...props
}: UnauthorizedClaimModalProps) {
  const {
    openUnauthorizedFormModal,
    setOpenUnauthorizedFormModal,
    solutionID,
    solutionName,
    isClaim,
    redirectURL,
    setStatus,
    solutionClaimed,
    solutionOwner,
    showRequestAQuote,
    contactUsBtn,
    logoUrl,
    company,
    setShowRequestAQuote,
    setContactUsBtn,
    paid_solution,
  } = props;
  const { display } = useWindowSize();
  const isMobile = display === 'mobile';
  const [recaptchaToken, setRecaptchaToken] = useState(false);
  const token = useToken();
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(true);
  const [isAgreeToPrivacyPolicy, setIsAgreeToPrivacyPolicy] = useState(true);
  const currentUserId = useCurrentUserId();
  const [isValid, setIsValid] = useState(true);
  const tracking = useTracking()

  const { data, setData, post, errors, transform, reset } = useForm({
    first_name: '',
    last_name: '',
    role: '',
    email: '',
    company: '',
    g_recaptcha_response: false,
  });

  const handleSubmitValidation = () => {
    return (
      data.first_name &&
      data.last_name &&
      data.email &&
      data.role &&
      (showRequestAQuote ? data.company : true) &&
      recaptchaToken &&
      isAgreeToPrivacyPolicy
    );
  };

  transform((data) => {
    return {
      user: { ...data, g_recaptcha_response: recaptchaToken },
      authenticity_token: token,
    };
  });

  async function submit() {
    const emailValid = (isEmailValid(data.email) && isWorkEmail(data.email))
    setIsValid(emailValid);
    if (!emailValid) { return; }
    if (isSubscribed) {
      subscribeToNewsLetter(data.email, token);
    }
    try {
      const formData = {
        solution_id: solutionID,
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
      };

      const sourceBtn = contactUsBtn
        ? 'contact_us'
        : showRequestAQuote
        ? 'request_a_quote'
        : 'other';
      const response = await axios.post(
        showRequestAQuote
          ? '/outside_leads'
          : isClaim
          ? '/outside_claims'
          : '/outside_leads',
        showRequestAQuote
          ? {
              outside_lead: {
                ...formData,
                role: data.role,
                company: data.company,
                source_button: sourceBtn,
              },
              g_recaptcha_response: recaptchaToken,
            }
          : isClaim
          ? {
              outside_claim: { ...formData, role: data.role },
              g_recaptcha_response: recaptchaToken,
            }
          : {
              outside_lead: {
                ...formData,
                role: data.role,
                company: data.company,
                source_button: sourceBtn,
              },
              g_recaptcha_response: recaptchaToken,
            },
        {
          headers: {
            'X-CSRF-Token': token,
          },
        }
      );
      if (response.status === 200) {
        setStatus('submitted');
        if (isClaim) {
          mixpanel.track('Submit Claim Button', {
            'Solution Name': solutionName,
            Submitted: true,
            'Entry Point': 'Solution Show Page',
          });
          event('Submit Claim Button', {
            'Solution Name': solutionName,
            Submitted: true,
            'Entry Point': 'Solution Show Page',
            userId: currentUserId,
          });
          tracking.trackEvent({
            event_name: 'claim_solution_form_submit',
            page_url: window.location.pathname,
            is_lead: true,
            event_loggable_type: "RmcEvents::RmcEvent",
            additional_attributes: { event_type: 'cta_submit' }
          })
        } else {
          mixpanel.track('Request Information Button', {
            'Solution Name': solutionName,
            Submitted: true,
            'Entry Point': 'Solution Show Page',
          });
          event('Request Information Button', {
            'Solution Name': solutionName,
            Submitted: true,
            'Entry Point': 'Solution Show Page',
            userId: currentUserId,
          });
          tracking.trackEvent({
            event_name: showRequestAQuote ? 'request_a_quote_form_submit' : 'contact_us_form_submit',
            page_url: window.location.pathname,
            event_loggable_type: "RmcEvents::RmcEvent",
            additional_attributes: { is_lead: true, event_type: 'cta_submit' }
          })
        }
      }
      setOpenSuccessModal(true);
      setOpenUnauthorizedFormModal(false);
    } catch (error) {
      if (isClaim) {
        mixpanel.track('Submit Claim Button', {
          'Solution Name': solutionName,
          Submitted: false,
          'Entry Point': 'Solution Show Page',
        });
      } else {
        mixpanel.track('Request Information Button', {
          'Solution Name': solutionName,
          Submitted: false,
          'Entry Point': 'Solution Show Page',
        });
      }
    }
  }

  // Claim Sent Successfully
  if (openSuccessModal) {
    return (
      <SuccessModal
        openSuccessModal={openSuccessModal}
        setOpenSuccessModal={setOpenSuccessModal}
        solutionName={solutionName}
        isClaim={isClaim}
        redirectURL={redirectURL}
        solutionClaimed={solutionClaimed}
        requestAQuote={showRequestAQuote}
      />
    );
  }

  if (openUnauthorizedFormModal) {
    return (
      <Modal
        isOpen={openUnauthorizedFormModal}
        onClose={() => {
          reset();
          setOpenUnauthorizedFormModal(false);
          setIsSubscribed(true);
          setShowRequestAQuote(false);
          setContactUsBtn(false);
        }}
        closeStyle="top-5 right-4"
        className="mx-4 w-full rounded-xl bg-white px-8 py-7 md:mt-0 md:w-[752px]"
      >
        <Form onSubmit={() => null}>
          <div>
            <h1 className="mb-4 text-2xl font-bold">
              {(contactUsBtn && 'Contact Us') ||
                (showRequestAQuote
                  ? `Request a Quote`
                  : isClaim
                  ? `Claim Solution?`
                  : `Request a Quote`)}
            </h1>
            <RequestModalHeading
              solutionName={solutionName}
              source={'claim'}
              contactUsBtn={contactUsBtn}
              showRequestAQuote={showRequestAQuote}
              isClaim={isClaim}
              solutionClaimed={solutionClaimed}
              paidSolution={paid_solution}
            />
            {!isMobile && (
              <>
                <div className="mb-4 flex">
                  <Input
                    label="First Name"
                    labelHtmlFor="first_name"
                    id="first_name"
                    type="text"
                    name="first_name"
                    value={data.first_name}
                    onChange={(e) => setData('first_name', e.target.value)}
                    containerClass="mr-4 w-1/2"
                    errors={errors.first_name}
                  />
                  <Input
                    label="Last Name"
                    labelHtmlFor="last_name"
                    id="last_name"
                    type="text"
                    name="last_name"
                    value={data.last_name}
                    containerClass="mr-4 w-1/2"
                    onChange={(e) => setData('last_name', e.target.value)}
                    errors={errors.last_name}
                  />
                </div>
                <div className="mb-6 flex">
                  <Input
                    label={showRequestAQuote || contactUsBtn ? 'Job Title' : 'Role at company'}
                    labelHtmlFor="role"
                    id="role"
                    type="text"
                    name="role"
                    value={data.role}
                    onChange={(e) => setData('role', e.target.value)}
                    containerClass="mr-4 w-1/2"
                    errors={errors.role}
                  />
                  <Input
                    label={showRequestAQuote || contactUsBtn ? 'Work Email' : 'Email address'}
                    labelHtmlFor="email"
                    id="email"
                    type="text"
                    name="email"
                    value={data.email}
                    containerClass="mr-4 w-1/2"
                    onChange={(e) => setData('email', e.target.value)}
                    errors={errors.email}
                  />
                </div>
                <div className="mb-6 flex">
                  {(showRequestAQuote || contactUsBtn) && (
                    <Input
                      label="Company"
                      labelHtmlFor="company"
                      id="company"
                      type="text"
                      name="company"
                      value={data.company}
                      containerClass="mr-2 w-1/2"
                      onChange={(e) => setData('company', e.target.value)}
                      errors={errors.company}
                    />
                  )}
                </div>
              </>
            )}

            {isMobile && (
              <div className="mb-4">
                <Input
                  label="First Name"
                  labelHtmlFor="first_name"
                  id="first_name"
                  type="text"
                  name="first_name"
                  value={data.first_name}
                  containerClass="mb-4"
                  onChange={(e) => setData('first_name', e.target.value)}
                  errors={errors.first_name}
                />
                <Input
                  label="Last Name"
                  labelHtmlFor="last_name"
                  id="last_name"
                  type="text"
                  name="last_name"
                  value={data.last_name}
                  containerClass="mb-4"
                  onChange={(e) => setData('last_name', e.target.value)}
                  errors={errors.last_name}
                />
                <Input
                  label={
                    showRequestAQuote
                      ? 'Job Title'
                      : isClaim
                      ? 'Role at Company'
                      : 'Current company'
                  }
                  labelHtmlFor="role"
                  id="role"
                  type="text"
                  name="role"
                  value={data.role}
                  containerClass="mb-4"
                  onChange={(e) => setData('role', e.target.value)}
                  errors={errors.role}
                />
                <Input
                  label={showRequestAQuote ? 'Work Email' : 'Email address'}
                  labelHtmlFor="email"
                  id="email"
                  type="text"
                  name="email"
                  value={data.email}
                  containerClass="mb-6"
                  onChange={(e) => setData('email', e.target.value)}
                  errors={errors.email}
                />
                {showRequestAQuote && (
                  <Input
                    label="Company"
                    labelHtmlFor="company"
                    id="company"
                    type="text"
                    name="company"
                    value={data.company}
                    containerClass="mb-6"
                    onChange={(e) => setData('company', e.target.value)}
                    errors={errors.company}
                  />
                )}
              </div>
            )}
          </div>
          <div className="mb-5 flex flex-col justify-between gap-3 sm:flex-row sm:items-center">
            <div className="w-full">
              <ReCaptcha setRecaptchaToken={setRecaptchaToken} />
            </div>
          </div>
          <div className="mb-3 flex flex-col justify-between">
            <ModalChecks
              isSubToWeeklyNewsletter={isSubscribed}
              setIsSubToWeeklyNewsletter={setIsSubscribed}
              isAgreeToPrivacyPolicy={isAgreeToPrivacyPolicy}
              setIsAgreeToPrivacyPolicy={setIsAgreeToPrivacyPolicy}
              showJobsNewsletterCheckbox={false}
            />
          </div>
          {!isValid && <p className="text-red-500 mt-2">Email should be a Work Email and in Valid Format !</p>}
          <div className="flex flex-col items-center gap-4 md:flex-row">
            <Button
              type="button"
              size="md"
              variant="secondary"
              className="w-full"
              onClick={() => {
                setOpenUnauthorizedFormModal(false);
                setShowRequestAQuote(false);
              }}
            >
              Cancel
            </Button>
            <Button
              type="button"
              size="md"
              variant="primary"
              className="w-full"
              disabled={!handleSubmitValidation()}
              onClick={submit}
            >
              {(showRequestAQuote || contactUsBtn)
                ? 'Submit Request'
                : isClaim
                ? 'Submit Claim'
                : 'Submit Request'}
            </Button>
          </div>
        </Form>
      </Modal>
    );
  }
}
