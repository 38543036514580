import { useState, useEffect, useRef, lazy } from 'react';
const Slider = lazy(() => import('@ant-design/react-slick'));
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from 'axios';
import arrow from '../../../../assets/images/arrow-icon.png';
import chavironLeft from '../../../../assets/images/chevron-left.svg';
import chavironBlue from '../../../../assets/images/chevron-blue.svg';
import default_article from '../../../../assets/images/default_image.png';
import TalivityLogo from '../../../../assets/images/talivity-logo.png';
import ArticleCard from '../../atoms/Article/ArticleCard';
import HybridLink from '@/components/atoms/widgets/HybridLink';
import { CustomDots, NextArrow, PrevArrow } from '@components/atoms/widgets/Carousel';
import he from 'he';
import window from 'global';
import useSSR from '@/hooks/useSSR';

import './styles.css';

type ArticleSectionProps = {
  articles: schema.Articles[];
  article_categories: schema.ArticleCategories[];
}

function Articles({ ...props }: ArticleSectionProps) {

  const isSSR = useSSR();

  const { articles, article_categories } = props

  const [activeFilter, setActiveFilter] = useState(article_categories ? article_categories[0] : '');
  const [articlesToShow, setArticlesToShow] = useState(articles);
  const containerRef = useRef(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 550);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1024 && window.innerWidth <= 1350);
  const [isTab, setIsTab] = useState(window.innerWidth <= 1024);
  const [isBetweenMobAndTab, setIsBetweenMobAndTab] = useState(window.innerWidth < 850 && window.innerWidth > 550);

  const wordpressBaseURL = 'https://www.recruitmentmarketing.com/';

  function extractDayWithSuffix(day) {
    if (day >= 11 && day <= 13) {
      return day + 'th';
    }
    switch (day % 10) {
      case 1: return day + 'st';
      case 2: return day + 'nd';
      case 3: return day + 'rd';
      default: return day + 'th';
    }
  }

  const extractDate = (date) => {
    const extractedDate = new Date(date)
    return `${extractedDate.toLocaleString('en-US', { month: 'long' }) + ' ' + extractDayWithSuffix(extractedDate.getDate()) + ', ' + extractedDate.getFullYear()}`
  }

  const getArticleUrl = (article) => {
    const pageURL = article.page_url.trim('/').split('/').filter(Boolean);
    const articleURL = pageURL[pageURL.length - 1];
    return `${wordpressBaseURL}${article.category_slug}/${articleURL}`;
  };

  const getArticles = async (title) => {
    try {
      const response = await axios.get('homepage/articles', {
        params: { category: title }
      });

      setArticlesToShow(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 550)
      setIsDesktop(window.innerWidth > 1024 && window.innerWidth <= 1350);
      setIsTab(window.innerWidth < 1160);
      setIsBetweenMobAndTab(window.innerWidth < 850 && window.innerWidth >= 540);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);

  const settings = {
    variableWidth: true,
    autoPlay: false,
    infinite: true,
    dots: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow customClasses={'mt-2 z-10 sm:z-0 absolute left-[77%] xl:left-[82%] lg:left-[84%] md:left-[82%] sm:left-[91%] xl:top-[100%] sm:w-[60px] sm:h-[60px] lg:top-[100%] md:top-[99%] top-[101%]'} />,
    nextArrow: <NextArrow customClasses={'mt-2 absolute left-[84%] xl:left-[85%] lg:left-[88%] md:left-[87%] xl:top-[100%] sm:left-[96%] sm:w-[60px] sm:h-[60px] lg:top-[100%] md:top-[99%] top-[101%]'} />,
    appendDots: (dots) => <CustomDots dots={dots} customClasses={'absolute left-[-35%] xl:left-[-32.7%] lg:left-[-39%] md:left-[-38%] top-[100%]'} />,
    centerMode: true,
    // className: 'center',
    centerPadding: isMobile ? '0%' : isBetweenMobAndTab ? '17%' : isTab ? '15%' : isDesktop ? '0%' : '8px'
  };

  return (
    <>
      {isSSR ? (
        <div className="flex justify-center">Loading...</div>
      ) : (
        <>
          <div className='w-[329px] sm:w-auto mx-auto flex flex-col 2xl:mx-auto max-w-[1320px] md:px-20 lg:px-44 xl:px-44'>
            <h1 className='sm:text-[1.75rem] xl:text-[2.375rem] text-[38px] font-extrabold -tracking-[0.5px] mb-[8px] md:mb-[27px]'>News</h1>
            <p className='text-[18px] sm:text-[0.7rem] md:text-lg font-semibold mb-[13px] md:mb-[30px] lg:w-[80%]'>Read the talent community's ultimate source for breaking industry news, top-notch practices, and insights from your peers.</p>
            <div className="flex w-full flex-col-reverse gap-[19px] md:flex-row flex-start justify-between">
              <div className='flex flex-start justify-between w-[100%] overflow-scroll scrollbar-none rounded-lg category-section-container'>
                <img src={chavironLeft} alt="prev" loading='lazy' className="block xl:hidden h-[15px] w-[15px] mr-4 sm:mt-[0.35rem] mt-[0.1rem] ml-[-2.3px] sm:ml-0" onClick={() => { containerRef.current.scrollLeft -= 200; }} />
                <div className="flex w-full gap-0 sm:gap-4 overflow-x-auto scrollbar-none" ref={containerRef}>
                  <div className="xl:hidden flex gradient-left"></div>
                  {article_categories.map((title, index) => (
                    <span
                      key={index}
                      className={`${title === activeFilter ? 'bg-[#f3f3f3] rounded-lg font-extrabold' : ''} flex items-center px-3 py-1 mb-[29px] sm:md-[50px] md:mb-[46px]`}
                      onClick={() => { setActiveFilter(title); getArticles(title); }}
                    >
                      <p className={`cursor-pointer text-[12px] sm:text-[0.75rem] text-black-100 hover:font-extrabold whitespace-nowrap`}>{title}</p>
                    </span>
                  ))}
                </div>
                <div className="xl:hidden flex gradient-right"></div>
                <img src={chavironLeft} alt="next" loading='lazy' className="block xl:hidden h-[15px] w-[15px] rotate-180 sm:mt-[0.35rem] mt-[0.1rem] ml-4" onClick={() => { containerRef.current.scrollLeft += 200; }} />
              </div>
              <div className="hidden md:flex gap-1 items-center pr-[1rem] xl:pr-[9px] mt-[3px] sm:mt-0 mb-[46px]">
                <HybridLink href={wordpressBaseURL + 'topics/'} className="text-xs sm:text-sm sm:font-medium cursor-pointer text-[#11D0B0] whitespace-nowrap">View All Articles</HybridLink>
                <img className="h-1.5 w-1.5 sm:h-[0.6rem] sm:w-[0.6rem] sm:mt-[0.2rem] sm:ml-[0.2rem] self-center" src={chavironBlue} alt="arrow" loading='lazy' />
              </div>
              <div className="md:hidden flex gap-1 items-center pr-[1rem] xl:pr-[9px] mt-[3px] sm:mt-0">
                <HybridLink href={wordpressBaseURL + 'topics/'} className="text-[17px] sm:text-sm sm:font-medium cursor-pointer text-[#11D0B0] whitespace-nowrap">View All Topics</HybridLink>
                <img className="h-1.5 w-1.5 sm:h-[0.6rem] sm:w-[0.6rem] sm:mt-[0.2rem] sm:ml-[0.2rem] self-center" src={chavironBlue} alt="arrow" loading='lazy' />
              </div>
            </div>
          </div>
          <div className="w-[100%] max-w-[1260px] mx-auto article-section-container">
            <div className="gradient-left"></div>
            <div className="gradient-right"></div>
            <Slider {...settings}>
              {articlesToShow.map((article, index) => (
                <HybridLink href={getArticleUrl(article)} key={index}>
                  <ArticleCard
                    arrow={arrow}
                    image={article.image ? article.image : default_article}
                    authorImage={TalivityLogo}
                    authorName={article.contributor_title}
                    title={article.title ? he.decode(article.title) : ''}
                    category={article.category_title}
                    date={extractDate(article.published_date)}
                    duration={article.min_read ? he.decode(article.min_read) : ''}
                    key={index}
                  />
                </HybridLink>
              ))}
            </Slider>
          </div>
        </>
      )}
    </>
  );

}

export default Articles
