import BetaPartners from '@/components/molecules/Marqee/BetaPartners'

const BetapartnersSection = () => {
  return (
    <div className='w-full max-w-[1900px] px-3 pb-[2rem] sm:mx-auto sm:w-auto sm:pb-[4rem] md:px-6 md:pb-[2rem] xl:px-10 2xl:px-40'>
      <h3
        className='lg:pt-18 py-12 text-center text-[23px] font-semibold'
        style={{ color: 'rgba(0, 0, 0, 0.28)' }}
      >
        Our Partners
      </h3>
      <BetaPartners/>
    </div>
  )
}

export default BetapartnersSection
