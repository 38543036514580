import { SolutionStarRating } from '@/components/molecules/solution/SolutionCard';
import { Link } from '@inertiajs/react';
import Button from '../../atoms/buttons/Button';
import SolutionPlaceHolder from '../../../../assets/images/solution-logo-placeholder.svg';

type HomepageSolutionCardProps = {
  solution: schema.Solution;
  activeFilter: string;
};

const HomepageSolutionCard = ({ ...props }: HomepageSolutionCardProps) => {
  const { solution, activeFilter } = props;
  const sortedCategories = solution?.categories_names
  ?.map((name, index) => ({ name, slug: solution.categories_slugs[index] }))
  .sort((a, b) => {
    if (a.name === activeFilter) return -1;
    if (b.name === activeFilter) return 1;
    return 0;
  })
  .slice(0, 3);

  return (
    <div className='content relative mb-[17px] w-[234px] flex flex-col sm:w-auto xl:w-[292px] sm:max-w-[315.41px] xl:max-w-[292px] h-[331.74px] sm:h-[440px] sm:max-h-[465px] justify-center sm:px-4 sm:py-10 sm:mx-4 sm:my-4 rounded-xl shadow-card my-1'>
      {solution?.is_featured && (
        <div className='absolute left-[-3%] top-[2%] w-[101.58px] sm:w-[40%] rounded-t-lg rounded-r-lg bg-dark-blue py-1 text-center text-xs sm:text-sm font-bold uppercase tracking-[1px] text-white before:absolute before:top-[100%] before:left-[0%] before:z-[10] before:border-4 before:border-r-4 before:border-t-4 before:border-r-dark-blue before:border-t-dark-blue'>
          Featured
        </div>
      )}
      <div className='mt-3 sm:mt-0 flex flex-col items-center gap-6'>
        <img
          src={solution.logo_url || SolutionPlaceHolder}
          alt='solution logo'
          className='w-[74.74px] h-[73.61px] sm:w-[102px] sm:h-[102px] rounded-full border border-[#979797] border-opacity-10 shadow-lg mt-4'
          rel='nofollow'
        />
        <p className='break-all text-[17px] lg:text-[16.15px] font-semibold text-center sm:leading-6 leading-4 sm:h-11 h-8'>
          {solution.name}
        </p>
      </div>
      {solution?.categories_names?.length > 0 && (
        <div className='flex flex-wrap justify-center md:justify-center h-16 overflow-scroll scrollbar-none'>
          {sortedCategories.map((category) => (
            <Link
              href={`/categories/${category.slug}`}
              className='bg-blue-tint-2 p-1 h-min rounded-xl px-2 whitespace-nowrap m-1'
            >
              <p className='text-[9px] sm:text-xs font-semibold'>{category.name}</p>
            </Link>
          ))}
        </div>
      )}
      <div className='flex flex-wrap justify-center overflow-hidden shadow-card mx-[21px] rounded-xl mb-[19px] w-[185.9px] h-[38.24px] sm:w-auto sm:mx-0 sm:px-2 sm:py-4 sm:h-auto'>
        <SolutionStarRating solution={solution} />
      </div>
      <Button
        variant='secondary'
        className='flex text-[12.54px] justify-center items-center sm:text-sm md:text-md w-[185.9px] h-[38.24px] md:h-[35] ml-[21px] sm:w-auto sm:ml-0 sm:py-4 sm:h-auto mb-[10px] sm:mb-0 !rounded-lg'
      >
        <Link href={`/solutions/${solution.slug}`} className='font-normal'>
          View Vendor Profile
        </Link>
      </Button>
    </div>
  );
};

export default HomepageSolutionCard;