import { PromoCards } from '@/utils/constants'

const PromoHeader = () => {
  return (
    <div className='flex md:max-h-[111px] md:min-h-[111px] lg:max-h-[200px] lg:min-h-[166px] rounded-lg shadow-service-hero 
        items-start justify-center relative md:top-[55.5px] lg:top-[83px] bg-white py-6'>
      {PromoCards.map((card, index) => (
        <div key={index} className='flex text-center items-center justify-center'>
          <div className='item-center md:mx-[2.8rem] lg:mx-[2rem] xl:mx-[4.4rem]'>
            <p className='md:text-[10.7px] lg:text-[16px]'>{card.title}</p>
            <p className='font-extrabold text-talivity-teal md:text-[32px] md:tracking-[-0.976px] lg:text-[48px] 
                        lg:tracking-[-1.399px] md:leading-[41px] lg:leading-[57px]'>
              {card.value}
            </p>
            <p className='md:text-[10.7px] md:leading-[11.35px] lg:text-[16px] lg:leading-[17px]'>{card.description}</p>
          </div>
          {index !== PromoCards.length - 1 && <div className='w-[1px] md:h-[63px] lg:h-[89px] bg-gray-400 self-center'></div>}
        </div>
      ))}
    </div>
  )
}

export default PromoHeader
