const MissionAccountSection = () => {
  return (
    <div className="w-full mx-auto bg-gray-100 py-16 sm:py-0">
    <div className='py-16  mx-auto px-9 pb-[1rem] pt-[3rem] text-center sm:px-0 sm:pb-16 sm:pt-[4.875rem] md:max-w-[578px] md:pt-[6rem] lg:max-w-[40rem] lg:pt-[3rem] xl:pb-[2rem]'>
      <h1 className='text-[2.813rem] md:text-[2.813rem] font-extrabold text-dark-blue'>
        The Talivity Mission
      </h1>
      <p className='lg:text-[1rem] md:text-[1.049rem] md:pt-[1rem] text-[1rem] leading-6 flex justify-center pt-[1.75rem] font-medium sm:w-full'>
        We connect you to the future of work.
        <br />
        We empower talent professionals with innovative tools, insights, and networks to shape tomorrow's workforce, today.
      </p>
    </div>
    </div>
  )
}
export default MissionAccountSection
