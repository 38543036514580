import { Disclosure } from '@headlessui/react'
import { IoChevronUp } from 'react-icons/io5'

type FaqsAccordionProps = {
  faqs: schema.Faqs[]
}

const FaqsAccordion: React.FC<FaqsAccordionProps> = ({ faqs }) => {
  return (
    <div className='mx-auto mt-4 w-full divide-y divide-gray-border rounded-2xl border border-gray-border bg-white'>
      {faqs.map((faq, index) => (
        <Disclosure key={index}>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={`flex w-full justify-between break-words bg-mystic px-4 py-[22px] text-left text-[16px] font-semibold text-outer-space ${index === 0 ? (faqs.length === 1 && !open ? 'rounded-2xl' : 'rounded-t-2xl') : index === faqs.length - 1 && !open ? 'rounded-b-2xl' : ``}`}
              >
                <span>{faq.question}</span>
                <IoChevronUp
                  className={`${!open && 'rotate-180'} size-5 transition-transform duration-300`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className='break-words px-4 py-[22px] text-[16px] font-normal text-outer-space'>
                {String(faq.answer)}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}
    </div>
  )
}

export default FaqsAccordion
