import { useState, useRef } from 'react'
import AvatarEditor from 'react-avatar-editor'
import { useMobileDetect } from '@/utils/detectMobile'

type CropComponentProps = {
  src: { name: string }
  setCroppedFile: (file: File) => void
  imageWidth?: number
}
  const CropComponent = ({ src, setCroppedFile, imageWidth } : CropComponentProps) => {
  const [scale, setScale] = useState(1)
  const editorRef = useRef<AvatarEditor>(null)
  const isMobile = useMobileDetect({ screenSize: 640 })

  const fileNameNew = src.name

  const SaveCrop = () => {
    if (editorRef.current) {
      handleCroppedImage(editorRef.current.getImageScaledToCanvas())
    }
  }

  const handleCroppedImage = (canvas: HTMLCanvasElement) => {
    canvas.toBlob((blob) => {
      if (!blob) {
        return
      }
      const file = new File([blob], fileNameNew, { type: 'image/png' })
      setCroppedFile(file)
    }, 'image/png', 1)
  }

  const handleScaleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newScale = parseFloat(e.target.value)
    setScale(newScale)
  }

  const h = isMobile ? 200 : 400
  const w = imageWidth ?? h

  return (
    <div>
      <AvatarEditor
        ref={editorRef}
        image={src}
        width={w}
        height={h}
        border={20}
        borderRadius={0}
        color={[0, 0, 0, 0.5]}
        scale={scale}
        rotate={0}
        style={{ width: '100%', maxWidth: w, maxHeight: h }}
        className='bg-cover bg-center'
        onImageChange={SaveCrop}
        onImageReady={SaveCrop}
      />
      <div className='mt-4'>
        <label htmlFor='scale' className='text-md font-semibold text-gray-500'>
          Zoom:
        </label>
        <input
          id='scale'
          type='range'
          min='1'
          max='3'
          step='0.01'
          value={scale}
          onChange={handleScaleChange}
          className='ml-2'
        />
      </div>
    </div>
  )
}

export default CropComponent
