import { lazy, useEffect, useState } from "react"
const Slider = lazy(() => import('@ant-design/react-slick'));
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import Desktop_Marketplace from '../../../../assets/images/D1.png'
import Desktop_Events from '../../../../assets/images/D2.png'
import Desktop_Newsletter from '../../../../assets/images/D3.png'
import Desktop_News from '../../../../assets/images/D4.png'
import Mobile_Marketplace from '../../../../assets/images/M1.png'
import Mobile_Events from '../../../../assets/images/M2.png'
import Mobile_Newsletter from '../../../../assets/images/M3.png'
import Mobile_News from '../../../../assets/images/M4.png'
import { CustomDots, NextArrow, PrevArrow } from '@components/atoms/widgets/Carousel'
import HighlightsCard from "./HighlightsCard"
import window from 'global'
import useSSR from "@/hooks/useSSR";

import './styles.css'
import useCurrentUser from "@/hooks/useCurrentUser"

const HighlightsSection = () => {

  const isSSR = useSSR();
  const [data,setData] = useState([
    { mobile: Mobile_Marketplace, desktop: Desktop_Marketplace, name: 'Marketplace' },
    { mobile: Mobile_Events, desktop: Desktop_Events, href: '/events', name: 'Events' },
    { mobile: Mobile_Newsletter, desktop: Desktop_Newsletter, name: 'Newsletter' },
    { mobile: Mobile_News, desktop: Desktop_News, href: 'https://talentjobs.niceboard.co/', name: 'Jobs' }
  ])
  const currentUser = useCurrentUser();

  useEffect(()=> {
    (currentUser?.newsletter_subscribed) && setData(prevData => prevData.filter(item => item.name !== 'Newsletter')) 
  }, [])

  const settings = {
    dots: true,
    infinite: true,
    speed: 200,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <PrevArrow customClasses={'absolute left-[82%] sm:left-[75%] xl:left-[89%] lg:left-[89%] md:left-[84%] xl:top-[99%] lg:top-[99%] md:top-[99%] top-[100%] mt-[1rem] lg:mt-[1rem]'} />,
    nextArrow: <NextArrow customClasses={'absolute left-[92%] sm:left-[83%] xl:left-[93%] lg:left-[93%] md:left-[90%] xl:top-[99%] lg:top-[99%] md:top-[99%] top-[100%] mt-[1rem] lg:mt-[1rem]'} />,
    appendDots: (dots) => <CustomDots dots={dots} customClasses={'mt-[1rem] lg:mt-[1rem] absolute left-[-43%] sm:left-[-39%] xl:left-[-48%] lg:left-[-48%] md:left-[-47%] top-[99%]'} />,
  };

  return (
    isSSR ? (
      <div className="flex justify-center">Loading...</div>
    ) : (
      <>
        <div className='max-w-[964px] w-[329px] xl:mx-auto sm:w-auto mx-auto md:px-10 lg:px-26 xl:px-0'>
          <Slider {...settings} className='slider'>
            {data.map((item, index) => (
              <HighlightsCard mobile={item.mobile} desktop={item.desktop} key={index} href={item.href} cardName={item.name} />
            ))}
          </Slider>
        </div>
      </>
    )
  )
}

export default HighlightsSection;
