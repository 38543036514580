import clsx from 'clsx'
import { Popover } from '@headlessui/react'

import Button from '@components/atoms/buttons/Button'
import HybridLink from '@components/atoms/widgets/HybridLink'
import { navLinks } from '../../atoms/constants/navLinks'
import { IoIosArrowBack } from 'react-icons/io'
import AllCategoriesSection from '@/Pages/Marketplace/AllCategoriesSection'

const MobilePopoverPanel = ({
  currentUser,
  handleLogout,
  handleSignUpTopNav,
  tracking,
  hideDropdown,
  handleCategoriesDropdown,
  dropdownRef,
  showCategoriesDropdown,
  setShowCategoriesDropdown,
  funnelCategories
}) => {
  return (
    <div className='relative'>
      <Popover.Panel>
        {({ close }) => (
          <div className='xl:[w-400px] absolute top-0 z-50 w-full sm:right-0 sm:top-[3px] sm:w-[224px] sm:shadow-nav-dropdown'>
            <div className='relative top-[-1px] flex h-full w-full flex-col justify-between bg-white px-5 pt-3 sm:px-5 sm:py-6'>
              <div className='flex flex-col gap-8 pb-12 sm:gap-[36px] xl:pt-3'>
                <span className='flex w-full cursor-pointer items-center justify-between text-[14px] leading-[20px] text-black
                  sm:leading-[10px] xl:text-3xl xl:leading-[42.26px] mb-[-4px] hover:font-semibold'
                  onClick={() => {
                    close()
                    handleCategoriesDropdown()
                  }}
                >
                  <div className='flex flex-col gap-2'>
                    <span>Marketplace</span>
                  </div>
                  <IoIosArrowBack className='text-[18px] rotate-180' />
                </span>
                {navLinks.map((link, index) => (
                  <Popover key={index}>
                    {() => (
                      <div>
                        <Popover.Button
                          className='flex w-full items-center gap-2'
                          onClick={() => {
                            if (link.external && link?.url) {
                              window.open(link.url, '_self')
                            }
                          }}
                        >
                          <HybridLink
                            isExternal={link.external}
                            key={index}
                            href={link.url}
                            className='flex w-full cursor-pointer items-center justify-start text-[14px] leading-[20px] text-black
                            sm:pr-1 sm:leading-[10px] xl:text-3xl xl:leading-[42.26px]'
                          >
                            {link.name}
                          </HybridLink>
                        </Popover.Button>
                      </div>
                    )}
                  </Popover>
                ))}
              </div>
              <div>
                {currentUser ? (
                  <div className='flex flex-col gap-3 border-t border-light-gray-hover py-5'>
                    <HybridLink
                      className='flex cursor-pointer items-center justify-end uppercase leading-[19.84px] text-black xl:pr-1 xl:text-xl'
                      href='/users/edit'
                      isExternal={false}
                    >
                      <Button
                        size='lg'
                        variant='secondary'
                        className='flex h-[56px] w-full items-center justify-center rounded-full'
                        style={{ padding: 0 }}
                      >
                        My Profile
                      </Button>
                    </HybridLink>
                    <Button
                      size='lg'
                      variant='tertiary'
                      className='flex h-[56px] w-full items-center justify-center rounded-full'
                      style={{ padding: 0 }}
                      onClick={() => handleLogout()}
                    >
                      Log Out
                    </Button>
                  </div>
                ) : (
                  <div className='flex w-full flex-col gap-3 border-t border-light-gray-hover px-1 py-5'>
                    <HybridLink
                      className='flex cursor-pointer items-center justify-end uppercase leading-[19.84px] text-black xl:pr-1 xl:text-xl'
                      href='/login'
                      isExternal={false}
                    >
                      <Button
                        size='lg'
                        variant='primary'
                        className='flex h-[44px] w-full items-center justify-center rounded-full'
                        style={{ padding: 0, fontSize: 16 }}
                      >
                        Vendor Login
                      </Button>
                    </HybridLink>
                    <Popover.Button
                      onClick={() => {
                        window.location.href = "https://members.talivity.com/claimprofile"
                        tracking.trackEvent({
                          event_name: 'sign_up',
                          page_url: window.location.pathname,
                          event_loggable_type: "RmcEvents::RmcEvent",
                          additional_attributes: { event_type: 'click_event' }
                        })
                      }}
                      className="flex h-[44px] w-full items-center justify-center rounded-xl bg-[#031931] hover:bg-dark-blue-hover
                      active:bg-dark-blue-clicked text-white px-4 py-[14px] text-[16px] font-semibold"
                      style={{ padding: 0 }}
                    >
                      Claim Profile
                    </Popover.Button>
                  </div>
                )}
              </div>
            </div>
            <div className='relative top-[-1px] h-[2px] bg-teal' />
          </div>
        )}
      </Popover.Panel>
      {(!hideDropdown && showCategoriesDropdown) &&
        <div
          className='flex text-left drop-shadow-xl md:fixed absolute mt-[2px] right-0
          justify-center overflow-y-scroll bg-white z-40 max-h-[625px] scrollbar-none max-w-[340px]'
          ref={dropdownRef}
        >
          <div className='w-full p-6'>
            <AllCategoriesSection funnelCategories={funnelCategories} contentForModal={true} setShowCategoriesDropdown={setShowCategoriesDropdown} />
          </div>
        </div>
      }
    </div>
  )
}

export default MobilePopoverPanel
