/* eslint-disable prettier/prettier */
import React, { useState } from 'react';
import Modal from '../../atoms/modals/Modal';
import Button from '../../atoms/buttons/Button';
import axios from 'axios';
import { useToken } from '@/components/atoms/forms/Form';
import { useForm } from '@inertiajs/react';
import Input from '../../atoms/inputs/Input';
import { toast } from 'react-toastify';
import { SuccessModal } from './ClaimSuccessModal';
import subscribeToNewsLetter from '@/utils/newsletterSubscription';
import ModalChecks from './ModalChecks';
import RequestModalHeading from '../../atoms/modals/RequestModalHeading';
import { isEmailValid, isWorkEmail } from '../../../utils/emailValidation';
import ReCaptcha from '../../atoms/widgets/Recaptcha';
import { useTracking } from 'react-tracking';

export type RequestDemoModalProps = {
  openRequestDemoModal: boolean;
  setOpenRequestDemoModal: React.Dispatch<React.SetStateAction<boolean>>;
  solution: schema.Solution;
  redirectURL: string;
  company: string;
};
const RequestDemoModal = ({
  openRequestDemoModal,
  setOpenRequestDemoModal,
  solution,
  redirectURL,
  company,
}: RequestDemoModalProps) => {
  const token = useToken();
  const [isSubscribed, setIsSubscribed] = useState(true);
  const [isAgreeToPrivacyPolicy, setIsAgreeToPrivacyPolicy] = useState(true);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [openRequestModal, setOpenRequestModal] = useState(openRequestDemoModal);
  const [recaptchaToken, setRecaptchaToken] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const tracking = useTracking()
  const { data, setData, errors} = useForm({
    first_name: '',
    last_name: '',
    role: '',
    email: '',
    company: '',
    g_recaptcha_response: false,
  });

  const showToast = (type, message) => {
    toast(
      <div>
        {message}
      </div>,
      {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        className:
          `${type == 'success' ? 'bg-teal-tint-2' : 'bg-red-200'} text-md text-black p-4 md:p-6 md:rounded-xl md:w-[400px] md:right-[80px]`,
        bodyClassName: 'p-0 min-w-[324px]',
      }
    );
  }
  const submit = async (e) => {
    const emailValid = (isEmailValid(data.email) && isWorkEmail(data.email))
    setIsValid(emailValid);
    if (!emailValid) { return; }
    try {
      const response = await axios.post(
        '/demo_requests',
        {
          demo_request: {
            ...data, solution_id: solution.id,
          },
          g_recaptcha_response: recaptchaToken
        },
        {
          headers: {
            'X-CSRF-Token': token,
          },
        }
      )
      
      if (response.data.status === 'successful') {
        if(isSubscribed) subscribeToNewsLetter(data.email, token);
        setData(response.data);
        showToast('success', `Your request was sent to ${company}`)
        setOpenSuccessModal(true);
        tracking.trackEvent({
          event_name: 'request_a_demo_form_submit',
          page_url: window.location.pathname,
          event_loggable_type: "RmcEvents::RmcEvent",
          additional_attributes: { event_type: 'cta_submit' }
        })
      }
      else {
        showToast('failure', `${response.data.errors[0]}`)
      }
    } catch (error) {
      showToast('failure', 'There is a problem with sending your request.')
    }
  };

  const handleValidation = () => {
    return (data.email && data.first_name
    && data.last_name && data.role && data.company
    && isAgreeToPrivacyPolicy && recaptchaToken)
  };

  return (
    openSuccessModal ? ( <SuccessModal
      openSuccessModal={openSuccessModal}
      setOpenSuccessModal={setOpenRequestDemoModal}
      solutionName={solution.name}
      redirectURL={redirectURL}
      requestAQuote={false}
    /> ) : (
    <Modal
      isOpen={openRequestModal}
      onClose={() => setOpenRequestDemoModal(false)}
      closeStyle="top-10 right-10"
      className="mx-4 w-full rounded-xl bg-white p-10 md:mt-0 md:w-[600px]"
    >
      <div className="flex flex-col">
        <h1 className="mb-3 justify-start text-2xl  font-bold md:mb-5 ">
          Request A Demo
        </h1>
        <RequestModalHeading
          solutionName={solution.name}
          source={'demo'}
        />
        <div className="flex flex-col">
          <div className="flex flex-col justify-between md:flex-row md:gap-3">
            <Input
              label="First Name"
              labelHtmlFor="first_name"
              id="first_name"
              type="text"
              name="first_name"
              value={data.first_name}
              containerClass="mb-6 w-full"
              onChange={(e) => setData("first_name", e.target.value)}
              errors={errors.first_name}
            />
            <Input
              label="Last Name"
              labelHtmlFor="last_name"
              id="last_name"
              type="text"
              name="last_name"
              value={data.last_name}
              containerClass="mb-6 w-full"
              onChange={(e) => setData("last_name", e.target.value)}
              errors={errors.last_name}
            />
          </div>
          <div className="flex flex-col justify-between md:flex-row md:gap-3">
            <Input
              label="Role at Company"
              labelHtmlFor="role"
              id="role"
              type="text"
              name="role"
              value={data.role}
              containerClass="mb-6 w-full"
              onChange={(e) => setData("role", e.target.value)}
              errors={errors.role}
            />
            <Input
              label="Company"
              labelHtmlFor="company"
              id="company"
              type="text"
              name="company"
              value={data.company}
              containerClass="mb-6 w-full"
              onChange={(e) => setData("company", e.target.value)}
              errors={errors.company}
            />
          </div>
          <div className="mb-3 flex flex-col justify-between">
            <Input
              label="Work Email"
              labelHtmlFor="email"
              id="email"
              type="text"
              name="email"
              value={data.email}
              containerClass="mb-6 w-full"
              onChange={(e) => setData("email", e.target.value)}
              errors={errors.email}
            />
            <div className="mb-5 flex flex-col justify-between gap-3 sm:flex-row sm:items-center">
              <div className="w-full">
                <ReCaptcha setRecaptchaToken={setRecaptchaToken} />
              </div>
            </div>
            <ModalChecks
              isSubToWeeklyNewsletter={isSubscribed}
              setIsSubToWeeklyNewsletter={setIsSubscribed}
              isAgreeToPrivacyPolicy={isAgreeToPrivacyPolicy}
              setIsAgreeToPrivacyPolicy={setIsAgreeToPrivacyPolicy}
              showJobsNewsletterCheckbox={false}
            />
            {!isValid && <p className="text-red-500 mt-2">Email should be a Work Email and in Valid Format !</p>}
          </div>
          <div className="flex flex-col items-center gap-4 md:flex-row">
            <Button
              type="button"
              size="md"
              variant="secondary"
              className="w-full"
              onClick={() => setOpenRequestDemoModal(false)}
            >
              Cancel
            </Button>
            <Button
              type="button"
              size="md"
              variant="primary"
              className="w-full"
              onClick={(e) => submit(e)}
              disabled={!handleValidation()}
            >
              Request Demo
            </Button>
          </div>
        </div>
      </div>
    </Modal>)
  );
};

export default RequestDemoModal;
