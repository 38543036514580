import { ourServicesCards } from '@/components/atoms/constants/OurServicesData'

function ServicesCards({ activeCardId, setActiveCardId }) {
  return (
    <div className="flex flex-row items-center justify-center">
      {ourServicesCards.map(card => (
        <div
          key={card.id}
          className={`cursor-pointer md:min-w-[219.18px] md:min-h-[150.7px] md:w-[219.18px] md:h-[150.7px] lg:w-[318px] lg:h-[218px] mx-2 md:p-[2px]
           lg:p-[3px] rounded-[12.5px] lg:rounded-[18px] shadow-service-card ${activeCardId === card.id ? 'bg-dark-teal' : 'bg-white'}`}
          onClick={() => setActiveCardId(card.id)}
        >
          <div className={`flex flex-col items-center text-center justify-around bg-white rounded-[10px]  lg:rounded-[15px] h-full w-full py-4`}>
            <img src={card.image} alt={"card heading"} loading='lazy' className={card.class}/>
            <h1 className={`${activeCardId === card.id ? 'text-dark-teal font-extrabold' : 'text-[#000] font-normal'} text-[14.55px] lg:text-[16px] xl:text-[20px] leading-[18.6px] lg:leading-[25px]`}>
              {card.heading}
            </h1>
          </div>
        </div>
      ))}
    </div>
  );
}

export default ServicesCards
