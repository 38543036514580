import { useState, useEffect } from 'react'
import HybridLink from '@/components/atoms/widgets/HybridLink'
import solutionLogoPlaceholder from '../../../../assets/images/solution-logo-placeholder.svg'
import SolutionStarRating from './SolutionStarRating'

type SimilarSolutionCardProps = {
  solution: schema.Solution
  solutionsToCompare?: schema.Solution[]
  setSolutionsToCompare?: React.Dispatch<React.SetStateAction<schema.Solution[]>>
}

const SimilarSolutionCard = ({
  solution,
  solutionsToCompare,
  setSolutionsToCompare,
}: SimilarSolutionCardProps) => {
  const [isChecked, setIsChecked] = useState(false)

  useEffect(() => {
    const solutionNames = solutionsToCompare?.map((s) => {
      return s.name
    })
    if (solutionNames?.includes(solution.name)) {
      setIsChecked(true)
    } else {
      setIsChecked(false)
    }
  }, [solutionsToCompare])

  const handleOnChange = (e) => {
    setIsChecked(e.target.checked)
  }

  const handleCompareSolution = (e) => {
    e.currentTarget.blur()
    let updatedSolutionsToCompare = [...solutionsToCompare]
    if (e.target.checked) {
      setIsChecked(true)
      if (!updatedSolutionsToCompare.includes(solution))
        updatedSolutionsToCompare.push(solution)
    } else {
      updatedSolutionsToCompare = updatedSolutionsToCompare.filter((s) => {
        if (s.name === solution.name) return false
        return true
      })
    }
    setSolutionsToCompare(updatedSolutionsToCompare)
  }

  return (
    <HybridLink href={`/solutions/${solution.slug}`} >
      <div className='flex flex-col rounded-lg shadow-service-hero max-h-[100px]'>
        <div className='flex self-end h-[20px]'>
          {solution.is_featured &&
            <p className='rounded-tr-lg rounded-bl-lg bg-ocean-blue px-[8px] py-[2px] text-[10.7px] font-semibold text-white tracking-[1px]'>
              Featured
            </p>
          }
        </div>
        <div className='flex flex-col gap-2 px-2 pb-2'>
          <div className='flex gap-3'>
            <div className='flex min-h-[50px] min-w-[50px] max-h-[50px] max-w-[50px]'>
              <img
                className='min-h-[50px] min-w-[50px] max-h-[50px] max-w-[50px] rounded-full'
                src={solution.logo_url || solutionLogoPlaceholder}
                alt='logo'
                rel='nofollow'
              />
            </div>
            <div className='flex flex-col'>
              <p className='font-bold text-md mt-1'>
                {solution.name}
              </p>
              {solution.ratings_count >= 0 && (
                <SolutionStarRating solution={solution} />
              )}
            </div>
          </div>

          {solutionsToCompare && (
            <div className='flex items-center gap-1 self-end'>
              <label className='text-xs font-semibold text-medium-gray'>Add To Compare</label>
              <input
                type='checkbox'
                onChange={(e) => handleOnChange(e)}
                className='border-medium-gray h-3 w-3 cursor-pointer rounded-sm checked:bg-dark-blue'
                onClick={(e) => {
                  e.stopPropagation()
                  handleCompareSolution(e)
                }}
                checked={isChecked}
                disabled={!(solutionsToCompare?.length < 4) && !isChecked}
              />
            </div>
          )}
        </div>

      </div>
    </HybridLink>
  )
}

export default SimilarSolutionCard
