import React, { useState, useRef, useEffect } from 'react';
import { solutionOptions } from '@/components/atoms/constants/solutionOptions';
import './multiselect.css';
import axios from 'axios';
import { useForm, Link, Head } from '@inertiajs/react';
import Form, { useToken } from '@/components/atoms/forms/Form';
import Layout from '@/components/organisms/layouts/Layout';
import Button from '@/components/atoms/buttons/Button';
import { Dropdown } from '@/components/Dropdown';
import Input from '@/components/atoms/inputs/Input';
import editPencil from '../../../assets/images/edit-pencil-no-bg-white.svg';
import editPencilBlackIcon from '../../../assets/images/edit-pencil-no-bg.svg';
import infoIcon from '../../../assets/images/info.png';
import editPencilIcon from '../../../assets/images/white-edit-pencil.png';
import solutionLogoPlaceholder from '../../../assets/images/solution-logo-placeholder.svg';
import clsx from 'clsx';
import Meta from '@/components/atoms/widgets/Meta';
import EditContactModal from '@/components/molecules/modals/EditContactModal';
import EditModal from '@/components/molecules/modals/EditModal';
import { Tooltip } from '@/components/atoms/widgets/TooltipBody';
import lockImage from '../../../assets/images/lock-closed.svg';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MultiSelect } from 'react-multi-select-component';

type SolutionEditProps = {
  solution: schema.Solution;
  current_user?: schema.User;
  company: schema.Company;
  formPath: string;
  logo_url: string;
};

export default function SolutionEdit({ ...props }: SolutionEditProps) {
  // eslint-disable-next-line no-console
  const { solution, current_user, company, formPath, logo_url, logo } = props;
  const [isSolutionClaimed, setIsSolutionClaimed] = useState(true);
  const [toggleContactTooltip, setToggleContactTooltip] = useState(false);
  const [toggleDemoTooltip, setToggleDemoTooltip] = useState(false);
  const [logoPreviewUrl, setLogoPreviewUrl] = useState(null);
  const [hasDemoContactChanged, setHasDemoContactChanged] = useState(false);
  const [dataChanged, setDataChanged] = useState(false);
  const token = useToken();
  const fileInputRef = useRef(null);

  const { data, setData, put, processing, errors, transform, reset, isDirty } =
    useForm({
      size: solution.size,
      industry_type: solution.industry_type,
      website: solution.website,
      description: solution.description,
      logo: logo,
    });

  const companySizeMap = {
    'self-employed': 'self_employed',
    '1-10 employees': 'ten',
    '11-50 employees': 'fifty',
    '51-200 employees': 'two_hundred',
    '201-500 employees': 'five_hundred',
    '501-1,000 employees': 'one_thousand',
    '1,001-5,000 employees': 'five_thousand',
    '5,001-10,000 employees': 'ten_thousand',
    '10,001+ employees': 'max',
  };

  const reverseCompanySizeMap = {
    self_employed: 'self-employed',
    ten: '1-10 employees',
    fifty: '11-50 employees',
    two_hundred: '51-200 employees',
    five_hundred: '201-500 employees',
    one_thousand: '501-1,000 employees',
    five_thousand: '1,001-5,000 employees',
    ten_thousand: '5,001-10,000 employees',
    max: '10,001+ employees',
  };

  const authorizeSolutionAgainstUser = async () => {
    const response = await axios.get(
      `/authorize_solution_against_user/${solution.id}`
    );
    if (response.data === 'Unauthorized') {
      setIsSolutionClaimed(false);
    } else {
      setIsSolutionClaimed(true);
    }
  };

  const [isEditContactModalOpen, setIsEditContactModalOpen] = useState(false);
  const handleEditContact = () => {
    setIsEditContactModalOpen(true);
  };

  const [isEditDemoUrlModalOpen, setIsEditDemoUrlModalOpen] = useState(false);
  const handleEditDemoUrl = () => {
    setIsEditDemoUrlModalOpen(true);
  };

  const setToggleDemoInfo = () => {
    setToggleDemoTooltip(true);
    setTimeout(() => {
      setToggleDemoTooltip(false);
    }, 2000);
  };
  const setToggleContactInfo = () => {
    setToggleContactTooltip(true);
    setTimeout(() => {
      setToggleContactTooltip(false);
    }, 2000);
  };

  const solutionTypeMap = {
    solution_suite: 'Solution Suite',
    point_solution: 'Point Solution',
  };

  transform((data) => {
    return {
      solution: data,
      authenticity_token: token,
    };
  });

  const handleLogoChange = (event) => {
    setData('logo', event.target.files[0]);
    setDataChanged(true);
    setLogoPreviewUrl(URL.createObjectURL(event.target.files[0]));
  };

  async function submit(e) {
    setHasDemoContactChanged(false);
    const response = await axios.put(
      `/solutions/${solution.id}`,
      {
        solution: {
          ...data,
        },
      },
      {
        headers: {
          'X-CSRF-Token': token,
          Accept: 'application/json',
        },
      }
    );
    if (response.status === 200) {
      setData(response.data);
      setDataChanged(false);
      toast(
        <div>
          <span className="font-bold">Success! </span>Your changes have been
          saved
        </div>,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          className:
            'bg-teal-tint-2 text-md text-black p-4 md:p-6 md:rounded-xl md:w-[400px] md:right-[80px]',
          bodyClassName: 'p-0 min-w-[324px]',
        }
      );
    }
  }

  const handleButtonClick = () => {
    const fileInput = fileInputRef.current;
    if (fileInput) {
      fileInput.click();
    }
  };

  const handleBack = () => {
    window.history.back();
  };

  const formatTargetMarketValues = (field) => {
    if (field === 'size') {
      const value = data.size.map((size) => {
        return size;
      });
      return value;
    } else {
      const value = data.industry_type.map((industry) => {
        return industry;
      });
      return value;
    }
  };

  const validateSolutionOptions = () => {
    const sizes = formatTargetMarketValues('size');
    solutionOptions.companySizes = solutionOptions.companySizes.map(
      (companySize) => {
        if (sizes.includes(companySize.name)) {
          companySize.unavailable = true;
        }
        return companySize;
      }
    );
    const industries = formatTargetMarketValues('industry');
    solutionOptions.industries = solutionOptions.industries.map((industry) => {
      if (industries.includes(industry.name)) {
        industry.unavailable = true;
      }
      return industry;
    });
  };

  const transformSelectedOptions = (field, selectedOptions) => {
    if (field === 'size') {
      selectedOptions = selectedOptions.map((sizeOption) => {
        if (companySizeMap[sizeOption.label]) {
          return companySizeMap[sizeOption.label];
        }
        return sizeOption.value;
      });
    }

    if (Array.isArray(selectedOptions) && selectedOptions.length > 0) {
      const labelsArray = selectedOptions
        .filter((option) => option.label !== undefined && option.label !== null)
        .map((option) => option.label);

      return labelsArray.length > 0 ? labelsArray : selectedOptions;
    }

    return selectedOptions;
  };

  const handleDropdownChange = (field, selectedOptions) => {
    const transformedOptions = transformSelectedOptions(field, selectedOptions);
    setData(field, transformedOptions);
    setDataChanged(true);
  };

  const transformedIndustryOptions = solutionOptions.industries.map(
    (industry) => ({
      label: industry.name,
      value: industry.value,
    })
  );

  const transformedCompanyOptions = solutionOptions.companySizes.map(
    (company) => ({
      label: company.name,
      value: company.value,
    })
  );

  const transformedSelectedSizeValues = formatTargetMarketValues('size').map(
    (value) => ({
      label: reverseCompanySizeMap[value],
      value: value,
    })
  );

  const transformedSelectedIndustryValues = formatTargetMarketValues(
    'industry'
  ).map((value) => ({
    label: value.label || value,
    value: value.label || value,
  }));

  const customValueRenderer = (selected, _options) => {
    const elements = document.querySelectorAll(
      '.dropdown-heading-value > span:first-child'
    );
    document.getElementsByClassName('');
    elements.forEach(function (element) {
      element.classList.add('flex');
    });
    return selected.length
      ? selected.map(({ label }) => (
          <div
            key={label}
            className="m-1 flex rounded-md bg-lighter-medium-gray p-1"
          >
            {label || label.value}
          </div>
        ))
      : 'No Items Selected';
  };

  return (
    <Layout noPadding={true} type="menu">
      <Meta
        title={
          solution?.name
            ? `${solution.name} - Connect with the Best Recruitment Marketing SaaS and Service Providers on the Talivity Marketplace`
            : `Connect with the Best Recruitment Marketing SaaS and Service Providers on the Talivity Marketplace`
        }
        description={`Connect with the best recruitment marketing SaaS and service providers for ${solution.name} on the Talivity Marketplace. Streamline your hiring process and find the best solutions for your business needs.`}
      />
      <ToastContainer />
      {/* Solution Header Container */}
      <div className="flex flex-col bg-light-gray px-4 py-10 md:flex-row md:justify-between md:px-10 lg:px-20">
        <div className="flex flex-col items-center md:flex-row md:items-start">
          <p className="mb-[28px] text-3xl font-bold text-black md:hidden">
            Solution Information
          </p>
          <img
            src={logoPreviewUrl || logo_url || solutionLogoPlaceholder}
            alt="solution logo"
            rel="nofollow"
            className={clsx(
              'mb-5 h-[100px] w-[100px] rounded-full md:mr-5',
              !logo_url && !logoPreviewUrl && 'border border-medium-gray'
            )}
          />
          <div className="md:w-[230px]">
            <Button
              size="md"
              variant="secondary"
              className="flex w-full items-center justify-center gap-[11px] md:max-w-[194px] md:py-[10px] md:text-md"
              onClick={handleButtonClick}
            >
              <img
                src={editPencil}
                alt="pencil icon"
                className="stroke-white md:h-[18.25px] md:w-[18.25px]"
              />
              Upload new logo
            </Button>
            <input
              id="image-input"
              type="file"
              accept=".jpg,.png,.gif"
              onChange={handleLogoChange}
              ref={fileInputRef}
              className="hidden"
            />
            <p className="mt-3 text-center text-sm text-black md:text-left">
              You can upload a JPG, PNG, or GIF image up to 256 x 256 pixels.
            </p>
          </div>
        </div>
        <div className="hidden flex-col items-center gap-4 md:flex md:w-[255px] md:justify-end lg:w-[300px]">
          {current_user && isSolutionClaimed && (
            <Button
              type="button"
              size="md"
              variant={!solution.hide_demo ? 'demo' : 'disable'}
              className="relative flex w-full flex-row content-center justify-center  gap-3  py-3.5"
              onLoad={() => authorizeSolutionAgainstUser()}
              onClick={
                !solution.hide_demo
                  ? () => handleEditDemoUrl()
                  : () => setToggleDemoInfo()
              }
            >
              <img
                src={!solution.hide_demo ? editPencilIcon : infoIcon}
                alt={`Edit Demo Url`}
                className="mt-1 p-0"
              />
              <p>Book a Demo</p>
              {toggleDemoTooltip && (
                <div className="absolute left-[50px]">
                  <Tooltip customClassName="w-[260px] h-auto">
                    <p>
                      To enable this feature, contact
                      sales@recruitmentmarketing.com
                    </p>
                  </Tooltip>
                </div>
              )}
            </Button>
          )}
          {current_user && isSolutionClaimed && (
            <Button
              type="button"
              size="md"
              variant={'disable'}
              className="relative flex  w-full flex-row content-center justify-center  gap-3  py-3.5"
              onLoad={() => authorizeSolutionAgainstUser()}
              onClick={() => setToggleContactInfo()}
            >
              <img
                src={infoIcon}
                alt="Edit Contact Icon"
                className="mt-1 p-0"
              />
              <p>Contact Us</p>
              {toggleContactTooltip && (
                <div className="absolute left-[50px]">
                  <Tooltip customClassName="w-[260px] h-auto">
                    <p>
                      To enable this feature, contact
                      sales@recruitmentmarketing.com{' '}
                    </p>
                  </Tooltip>
                </div>
              )}
            </Button>
          )}
        </div>
      </div>

      {/* Solutions Form Container */}

      <div className="flex flex-col gap-[16px] px-4 pt-10 md:px-10 md:pt-12 md:pb-20 lg:max-w-[960px]  lg:px-20">
        <Form className="mb-0" onSubmit={submit}>
          {/* Overview Edit Container */}
          <div className="">
            <h1 className="mb-5 text-2xl font-bold text-black">Overview</h1>
            <Input
              type="text"
              label="Solution name"
              labelHtmlFor="solution_name"
              id="solution_name"
              disabled
              name="solution_name"
              value={solution.name}
              containerClass="mb-7"
              errors={errors.name}
            />
          </div>
          <div className="">
            <div className="flex flex-col gap-4">
              <div className="flex gap-2">
                <p>Website</p>
                <img src={lockImage} alt="" />
              </div>
              <Input
                type="text"
                labelHtmlFor="website"
                id="website"
                disabled
                name="website"
                value={data.website}
                onChange={(e) => setData('website', e.target.value)}
                containerClass="mb-7"
                errors={errors.website}
              />
            </div>
            <label
              htmlFor="description"
              className="mb-3 block text-lg text-black"
            >
              About
            </label>
            <textarea
              className="mb-0 h-[383px] w-full rounded-xl border border-dark-gray px-5 py-4 text-black focus:border-dark-gray focus:outline-none focus:ring-0 md:h-[221px] lg:h-[194px]"
              htmlFor="description"
              value={data.description}
              onChange={(e) => {
                setData('description', e.target.value);
                setDataChanged(true);
              }}
            />
          </div>
        </Form>
        <div className="mb-10 flex flex-col gap-[16px]">
          <h1 className="text-2xl font-bold text-black">Target Market</h1>
          <div
            className="max-w-[800px] md:flex md:gap-5"
            onLoad={validateSolutionOptions}
          >
            <MultiSelect
              options={transformedCompanyOptions}
              value={transformedSelectedSizeValues}
              onChange={(selectedOptions) => {
                handleDropdownChange('size', selectedOptions);
              }}
              labelledBy="Company size"
              className="w-full max-w-md"
              valueRenderer={customValueRenderer}
            />

            <MultiSelect
              options={transformedIndustryOptions}
              value={transformedSelectedIndustryValues}
              onChange={(selectedOptions) => {
                handleDropdownChange('industry_type', selectedOptions);
              }}
              labelledBy="Industry"
              className="w-full max-w-md"
              valueRenderer={customValueRenderer}
            />
          </div>
        </div>
        <div className="">
          {/* Solutions Claim and Contact Container Button Container*/}
          <div className="flex flex-col items-center gap-4 md:flex-row-reverse md:justify-end">
            {current_user && isSolutionClaimed && (
              <Button
                type="button"
                size="md"
                variant={!solution.hide_demo ? 'demo' : 'disable'}
                className="relative flex w-full flex-row content-center justify-center  gap-3  py-3.5 sm:hidden"
                onLoad={() => authorizeSolutionAgainstUser()}
                onClick={
                  !solution.hide_demo
                    ? () => handleEditDemoUrl()
                    : () => setToggleDemoInfo()
                }
              >
                <img
                  src={!solution.hide_demo ? editPencilIcon : infoIcon}
                  alt={`Edit Demo Url`}
                  className="mt-1 p-0"
                />
                <p>Book a Demo</p>
                {toggleDemoTooltip && (
                  <div className="absolute left-[50px]">
                    <Tooltip customClassName="w-[260px] h-auto">
                      <p>
                        To enable this feature, contact
                        sales@recruitmentmarketing.com
                      </p>
                    </Tooltip>
                  </div>
                )}
              </Button>
            )}
            {current_user && isSolutionClaimed && (
              <Button
                type="button"
                size="md"
                variant={'disable'}
                className="relative flex  w-full flex-row content-center justify-center  gap-3  py-3.5 sm:hidden"
                onLoad={() => authorizeSolutionAgainstUser()}
                onClick={() => setToggleContactInfo()}
              >
                <img
                  src={infoIcon}
                  alt="Edit Contact Icon"
                  className="mt-1 p-0"
                />
                <p>Contact Us</p>
                {toggleContactTooltip && (
                  <div className="absolute left-[50px]">
                    <Tooltip customClassName="w-[260px] h-auto">
                      <p>
                        To enable this feature, contact
                        sales@recruitmentmarketing.com{' '}
                      </p>
                    </Tooltip>
                  </div>
                )}
              </Button>
            )}
            {isEditDemoUrlModalOpen && (
              <EditModal
                openEditDemoModal={isEditDemoUrlModalOpen}
                setOpenEditDemoModal={setIsEditDemoUrlModalOpen}
                solution={solution}
                setHasDemoChanged={setHasDemoContactChanged}
              />
            )}
            {isEditContactModalOpen && (
              <EditContactModal
                openContactModal={isEditContactModalOpen}
                setOpenContactModal={setIsEditContactModalOpen}
                solution={solution}
                setHasContactChanged={setHasDemoContactChanged}
              />
            )}
          </div>
        </div>
      </div>
      <div className="sticky bottom-0 my-4 flex w-full flex-col gap-4 bg-white p-4 shadow-sticky-bar md:flex md:flex-row lg:px-20">
        <Button
          type="submit"
          disabled={!hasDemoContactChanged && !dataChanged}
          size="md"
          variant="primary"
          className="w-full disabled:border lg:w-[290px]"
          onClick={submit}
        >
          Save Changes
        </Button>
        <Button
          onClick={() => handleBack()}
          type="button"
          size="md"
          variant="secondary"
          className="w-full lg:w-[290px]"
        >
          Cancel Changes
        </Button>
      </div>
    </Layout>
  );
}
