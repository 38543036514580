import Button from '@/components/atoms/buttons/Button'
import clsx from 'clsx'
import React, { useState } from 'react'
import { IoChevronDown } from 'react-icons/io5'
import minifyString from '@/utils/minifyString'

const CategoryHeroSection = ({ category, parent_category, scrollToComponent }) => {
  const [showMore, setShowMore] = useState(category.description?.length > 300)
  const trimmedDescription = category.description?.trim()
  const shouldMinify = trimmedDescription?.length > 300 && showMore
  const displayDescription = shouldMinify
    ? minifyString(trimmedDescription, 300, 300, true)
    : trimmedDescription
  const currentYear = new Date().getFullYear()


  return (
    <div className='py-8 sm:py-8 xl:py-20 sm:px-0 px-10'>
      <div className='text-white sm:gap-8 md:flex md:justify-between xl:gap-10'>
        <div className='xl:w-[800px] flex flex-col justify-center'>
          {parent_category && (
            <div className='flex items-center mb-4'>
              <div className='bg-white h-[25px] w-[25px] rounded-full'></div>
              <p className='ms-3 text-[1.5rem] leading-[42.5px]'>
                {parent_category}
              </p>
            </div>
          )}
           <h1 className='mb-5 font-bold leading-[42.5px] sm:mb-6 text-[1.75rem] xl:text-[2.375rem]'>
            {
              category.is_best_category
                ? `Best ${category.name} for ${currentYear}`
                : `${category.name}`
            }
          </h1>
          <p
            className={clsx(
              'mb-5 w-full leading-[27px] text-[1rem] sm:leading-[25px]'
            )}
          >
            {displayDescription + ' '}
            {trimmedDescription?.length > 300 && (
              <button
                className='text-white'
                onClick={() => setShowMore(!showMore)}
              >
                {showMore ? 'Show more' : 'Show less'}
              </button>
            )}
          </p>
          <Button
            variant='secondary'
            className='flex justify-center gap-1 items-center self-center md:self-start whitespace-nowrap sm:px-3 px-4 sm:py-2 py-2 text-[0.75rem] !rounded-md md:text-xs mb-5 sm:mb-8 md:mb-10 font-semibold'
            onClick={scrollToComponent}
          >
            <p>View All Solutions</p>
            <IoChevronDown className='text-[1rem] md:text-md' />
          </Button>
        </div>
      </div>
    </div>
  )
}

export default CategoryHeroSection
