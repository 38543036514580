import HybridLink from '@/components/atoms/widgets/HybridLink';
import Podcasts from "../../../../assets/images/podcastsection.png";
import chavironBlue from '../../../../assets/images/chevron-blue.svg'
import window from 'global';

const PodcastsSection = () => {
  return (
    <>
      <div className='max-w-[1572px] w-[329px] sm:w-auto mx-auto sm:mx-0 2xl:mx-auto 2xl:px-40 xl:px-10 md:px-6 sm:px-3 xl:mx-[2.188rem]'>
        <div className='sm:px-4 md:px-10 xl:px-46 lg:px-36'>
          <div className='flex sm:flex-row flex-col justify-between sm:items-center'>
            <h1 className="sm:text-[1.75rem] xl:text-[2.375rem] text-[38px] font-bold">Podcasts</h1>
            <HybridLink
              href='https://www.recruitmentmarketing.com/podcasts'
              isExternal={true}
            >
              <div className='flex items-center gap-1 mt-[3px] sm:mt-0'>
                <span className='text-[17px] sm:text-sm sm:font-medium cursor-pointer text-[#11D0B0] whitespace-nowrap'>View All Podcasts</span>
                <img
                  className="h-2 w-2 mt-[0.2rem] sm:h-[0.6rem] sm:w-[0.6rem] sm:mt-[0.2rem] sm:ml-[0.2rem] self-center"
                  src={chavironBlue}
                  alt="arrow"
                  loading='lazy'
                />
              </div>
            </HybridLink>
          </div>
        </div>
      </div>
      <div className='max-w-[1409px] mx-auto relative overflow-hidden'>
        <div
          style={{
            backgroundImage: window.innerWidth > 720
              ? 'linear-gradient(to right, white 21%, transparent 30%, transparent 100%, white 46%), linear-gradient(to left, white 21%, transparent 35%, transparent 100%, white 58%)'
              : 'linear-gradient(to right, white 35%, transparent 40%, transparent 100%, white 46%), linear-gradient(to left, white 35%, transparent 40%, transparent 100%, white 58%)'
          }}
          className='absolute inset-[-500px] sm:blur-[10px]'
        />
        <img
          src={Podcasts}
          alt="podcast"
          loading='lazy'
          className='object-cover w-[430px] h-[250px] mx-auto mt-10 sm:mt-auto sm:w-full sm:h-auto'
          rel="nofollow"
        />
      </div>
    </>
  );
};

export default PodcastsSection;
