import { teamMembers } from '../../atoms/constants/teamMembers'
import TeamMemberCard from '@components/molecules/Team/TeamMemberCard'

const MeetMyTeam = () => {
    return (
      <>
        <div className='pt-[7.3rem] lg:pt-[6rem]'>
          <div className='flex flex-col items-center'>
            <h3 className='text-[2.3rem] md:text-[2.375rem] font-bold text-dark-blue'>
              Meet our team
            </h3>
            <p className='md:text-lg text-[1.125rem] text-black pt-[0.5rem] text-center px-[1.25rem] md:px-0'>
              Your trusted partners for future of work success
            </p>
          </div>
          <div className='flex flex-wrap gap-[0.5rem] lg:gap-[1.6rem] justify-center pt-[1.5rem] md:pt-[2rem] lg:pt-[2rem] md:px-0'>
            {teamMembers.map((member, index) => (
              <TeamMemberCard
                key={index}
                name={member.name}
                designation={member.designation}
                linkedinUrl={member.linkedinUrl}
                imgSrc={member.imgSrc}
              />
            ))}
          </div>
        </div>
      </>
    )
  }
  
  export default MeetMyTeam

