import Button from '@components/atoms/buttons/Button'
import { IoClose } from "react-icons/io5"
import { FaGripLines } from "react-icons/fa6"
import TalivityLogo from './TalivityLogo'
import { Popover } from '@headlessui/react'
import NavbarSearch from './NavbarSearch'
import { browserName } from 'react-device-detect'

const MobileNav = ({
  open,
  currentUser,
  handleLogout,
  handleSignUpTopNav,
  rootUrl,
  rootUrlExternal,
  isMobile,
  hideDropdown,
  setHideDropdown,
  showCategoriesDropdown,
  setShowCategoriesDropdown
}) => {

  const handleButtonClick = () => {
    if (currentUser) {
      handleLogout()
    } else {
      handleSignUpTopNav()
    }
  }

  return (
    <>
      <TalivityLogo
        rootUrl={rootUrl}
        rootUrlExternal={false}
      />
      { 
      !isMobile && browserName === 'Chrome' && 
        <NavbarSearch hideDropdown={hideDropdown} setHideDropdown={setHideDropdown}/>
      }
      <div className='flex items-center sm:gap-2 focus:outline-none focus:outline-0 focus:ring-0'>
        {!open && (
          <Button
            onClick={() => window.location.href = 'https://members.talivity.com/claimprofile'}
            variant='darkBlue'
            className='flex mr-[22px] sm:mr-0 h-[20px] w-[58px] text-[7.73px] sm:text-sm sm:h-[44px] sm:w-[110px] items-center justify-center !rounded-[4.64px] sm:rounded-[6px]'
            style={{ padding: 0 }}
          >
            {currentUser ? 'Log Out' : 'Claim Profile'}
          </Button>
        )}
        {open ?
          (<div className='flex flex-row items-center'>
            <div className='text-md leading-[36px] text-black'>
              Close
            </div>
            <button> <IoClose className='text-[25px] ml-1' /> </button>
          </div>)
          :
          <Popover.Button>
            <FaGripLines className='text-[28px] ml-1 cursor-pointer'
              onClick={() => {
                if (showCategoriesDropdown)
                  setShowCategoriesDropdown(false)
              }}
            />
          </Popover.Button>
        }
      </div>
    </>
  )
}

export default MobileNav
