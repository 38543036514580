import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import SearchIcon from '../../../assets/images/icon-search.svg'
import closeIcon from '../../../assets/images/close-icon-gray.svg';
import { useTracking } from 'react-tracking'
import { useDebounce } from '@/hooks/useDebounce';
import SearchDropdown from '@/components/molecules/homepageSearch/SearchDropdown';
import { FiSearch } from "react-icons/fi";
import useOutsideClick from '@/hooks/useOutsideClick';

const HomepageSearch = () => {
  const DEBOUNCE_DELAY = 2000;
  const [query, setQuery] = useState('');
  const debouncedQuery = useDebounce(query)
  const trackedQuery = useDebounce(query, DEBOUNCE_DELAY)
  const [results, setResults] = useState({ solutions: [], articles: [], events: [], categories: [] });
  const [showDropdown, setShowDropdown] = useState(false);
  const tracking = useTracking();
  const sections = [
    { results: results.categories, heading: 'Categories' },
    { results: results.articles, heading: 'Articles' },
    { results: results.solutions, heading: 'Solutions' },
    { results: results.events, heading: 'Events' }
  ];

  useEffect(() => {
    const fetchSearchResults = async () => {
      try {
        const response = await axios.get('/search', { params: { query: debouncedQuery } });
        setResults(response.data);
        setShowDropdown(true);
      } catch (error) {
        // Error Handling Here
      }
    };
    if (debouncedQuery) {
      fetchSearchResults();
    } else {
      setResults({ solutions: [], articles: [], events: [], categories: [] });
      setShowDropdown(false);
    }
  }, [debouncedQuery]);

  useEffect(()=>{
    if (trackedQuery === '') return;
    
    tracking.trackEvent({
      event_name: 'Homepage Search',
      page_url: window.location.pathname,
      event_loggable_type: "RmcEvents::SearchLog",
      additional_attributes: { searched_term: trackedQuery }
    });
  }, [trackedQuery]);

  const closeSearchResults = () => {
    setShowDropdown(false)
  }

  const ref = useRef()
  useOutsideClick(ref, showDropdown, closeSearchResults)

  const handleChange = (e) => {
    setQuery(e.target.value);
  };

  const handleSelectResult = (result) => {
    let visit_info = null;
    const pathMap = {
      'Solution': '/solutions/',
      'Category': '/categories/'
    };
    visit_info = pathMap[result.model_name] ? `${pathMap[result.model_name]}${result.slug}` : result.page_url;
    if (visit_info) {
      tracking.trackEvent({
        event_name: 'Homepage Search',
        page_url: window.location.pathname,
        event_loggable_type: "RmcEvents::SearchLog",
        additional_attributes: { searched_term: trackedQuery, clicked_url: visit_info }
      });
      window.open(visit_info, '_blank');
    }
  };

  const handleSearchDropdown = () => {
    setQuery('');
    setShowDropdown(false);
  }
  
  const openSearchResults = () => {
    if(query) setShowDropdown(true)
  }

  return (
    <div className='flex justify-center relative' ref={ref}>
      <div className='block'>
      <div className="flex items-center relative">
      <input className="2xs:w-[19rem] sm:w-[35rem] h-[3.25rem] w-[22rem] rounded-full font-normal !outline-none hover:cursor-text border-none placeholder-gray-300 pl-14"
      type="text" 
          placeholder="Search" 
          value={query} 
          onChange={handleChange}
          style={{ boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)' }}
          onClick={openSearchResults}
        />
        <FiSearch className="absolute left-4 size-[24px]" color='#2E343A61'/>
        {showDropdown && <img src={closeIcon} className='absolute right-2 w-7 h-7 hover:cursor-pointer' onClick={handleSearchDropdown}/>}
      </div>
        {showDropdown && (
          <div className="drop-shadow-lg z-10 p-5 dropdown bg-white md:w-[35rem] xl:h-[14.5rem] lg:h-[15rem] md:h-[13rem] w-[20rem] rounded-lg absolute max-h-60 overflow-y-scroll">
            <div className="dropdown-menu text-left">
              <div className="dropdown-content">
              {sections.map((section, index) => (
                <React.Fragment key={index}>
                  <SearchDropdown 
                    results={section.results} 
                    heading={section.heading} 
                    handleSelectResult={handleSelectResult} 
                  />
                  {index < sections.length - 1 && <div className='border-t border-gray-200 my-2'></div>}
                </React.Fragment>
              ))}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default HomepageSearch;
