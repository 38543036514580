import SolutionEvaluatorImage from 'app/assets/images/IphoneImage.png'
import { SolutionEvaluatorSteps } from 'app/javascript/utils/constants'

const SolutionEvaluator = () => {
 
  return (
    <div className='flex w-full flex-col-reverse md:flex-row'>
      <div className='flex w-full items-center justify-center pt-12 md:w-1/2 md:pt-0'>
        <img src={SolutionEvaluatorImage} alt='How it works' className='min-h-[400px] min-w-[340px] max-h-[500px] lg:max-h-[600px]' />
      </div>
      <div className='flex w-full flex-col md:w-1/2 md:pl-20 py-8'>
        <div className='flex w-full flex-col items-center gap-6 md:items-start lg:gap-8'>
          <h2 className='text-xl md:text-[1.75rem] xl:text-[38px] font-extrabold text-center sm:text-left'>Discover, Compare, and Select Leading HR Tech with Ease</h2>
          <ol className='flex flex-col gap-6 text-center lg:gap-8 md:text-start'>
            {SolutionEvaluatorSteps.map((step, index) => (
              <div key={index} className='flex flex-col items-center md:flex-row md:gap-5'>
                <img src={step.logo} alt='step-logo' className='size-[50px]' />
                <li key={index} className='mx-[20%] pt-4 text-[14px] md:mx-0 md:pt-0 lg:text-md'>
                  <b className='text-sm md:text-md'>{step.title}</b> {step.description}
                </li>
              </div>
            ))}
          </ol>
        </div>
      </div>
    </div>
  )
}

export default SolutionEvaluator
