import { createInertiaApp } from '@inertiajs/react';
import { createRoot } from 'react-dom/client';
import './index.css';
import { init as initFullStory } from '@fullstory/browser';
import VisitorPopupModal from '@/components/molecules/modals/VisitorPopupModal';
import { track } from 'react-tracking';
import { sendTrackingDataToServer } from '@/utils/tracking';
import ga4 from 'react-ga4';
import { ToastContainer } from 'react-toastify'
import closeIcon from '../../assets/images/close-icon-black.svg'

// @ts-ignore
const pages = import.meta.glob('../Pages/**/**.tsx', { eager: true });

const app = document.getElementById('app');

const GA_MEASUREMENT_ID = import.meta.env.VITE_GA_MEASUREMENT_ID;
ga4.initialize(GA_MEASUREMENT_ID);

initFullStory({
  orgId: 'o-1KS4S3-na1',
});

if (Boolean(app)) {
  createInertiaApp({
    resolve: (name: String) => {
      const page = pages[`../Pages/${name}.tsx`];
      if (!page) {
        throw new Error(`Could not resolve page '${name}'`);
      }

      return page;
    },
    setup({ el, App, props }) {
      const { currentUser } = props.initialPage.props
      const root = createRoot(el);
      const TrackedApp = track(
        { Component: "Apps" },
        {
          dispatch: data => {
            sendTrackingDataToServer(data)
          }
        }
      )(App);
      root.render(
        <>
          <ToastContainer
            closeButton={<img src={closeIcon} className="h-6 w-6" alt="close" />}
          />
            <TrackedApp {...props} />
          </>
      );
    },
  });
}
