import TalivityLogo from './TalivityLogo'
import NavLinks from '../../molecules/navbar/NavLinks'
import Button from '@components/atoms/buttons/Button'
import { navLinks, rightNavLinks } from '../../atoms/constants/navLinks'
import NavbarSearch from './NavbarSearch'
import { MdKeyboardArrowDown } from 'react-icons/md'
import AllCategoriesSection from '@/Pages/Marketplace/AllCategoriesSection'
import { browserName } from 'react-device-detect'

const DesktopNav = ({
  currentUser,
  rootUrl,
  handleSignUpTopNav,
  handleLogout,
  hideDropdown,
  setHideDropdown,
  dropdownRef,
  showCategoriesDropdown,
  setShowCategoriesDropdown,
  funnelCategories,
  handleCategoriesDropdown,
  toggleRef
}) => {

  return (
    <>
      <div className='flex'>
        <TalivityLogo rootUrl={rootUrl} rootUrlExternal={false} />
        <div className='flex flex-col pt-4'>
          <div className='flex items-center'>
            <p className='flex cursor-pointer items-center justify-end pr-3.5 text-sm leading-none hover:font-semibold'
              ref={toggleRef}
              onClick={handleCategoriesDropdown}
              onMouseEnter={handleCategoriesDropdown}
            >
              Marketplace <MdKeyboardArrowDown className='mt-0.5 text-md' />
            </p>
          </div>
        </div>
        <div className={'flex'}>
          <div className='flex items-center gap-[12px]'>
            <NavLinks currentUser={currentUser} navLinks={navLinks} />
          </div>
        </div>
      </div>
      {
        browserName === 'Chrome' && 
        <NavbarSearch hideDropdown={hideDropdown} setHideDropdown={setHideDropdown} />
      }
      <div className='flex items-center gap-[20px] sm:gap-[8px] ml-2'>
        <NavLinks
          currentUser={currentUser}
          navLinks={[currentUser ? rightNavLinks[1] : rightNavLinks[0]]}
        />
        {!currentUser ? (
          <Button
            size='md'
            onClick={() => window.location.href = 'https://members.talivity.com/claimprofile'}
            variant='darkBlue'
            className='flex h-[40px] w-[140px] items-center justify-center rounded-full py-[4px] text-sm'
            trackBtnClick={true}
          >
            Claim Profile
          </Button>
        ) : (
          <Button
            size='md'
            variant='darkBlue'
            className='flex h-[44px] w-[92px] items-center justify-center rounded-full'
            style={{ padding: 0 }}
            onClick={() => handleLogout()}
          >
            Log Out
          </Button>
        )}
      </div>
      {(!hideDropdown && showCategoriesDropdown) &&
        <div
          className='flex text-left drop-shadow-xl rounded-lg md:fixed md:inset-x-48 md:inset-y-16 absolute md:w-auto w-[20rem]
          justify-center pt-4 overflow-y-scroll bg-white z-40 max-h-[625px] scrollbar-none max-w-[1400px] mx-auto'
          ref={dropdownRef}>
          <div className='w-full max-w-[1156px] p-6'>
            <AllCategoriesSection funnelCategories={funnelCategories} contentForModal={true} setShowCategoriesDropdown={setShowCategoriesDropdown} />
          </div>
        </div>
      }
    </>
  )
}

export default DesktopNav
