import { useEffect, useRef, useState, lazy, Suspense } from 'react'
import RequestDemoModal from '../modals/RequestADemoModal'
import EditContactModal from '../modals/EditContactModal'
import { useTracking } from 'react-tracking'
import StickyNavbar from '../navbar/stickyNavbar'
import ClaimSolutionButton from '@/components/atoms/buttons/ClaimSolutionButton'
import { Ratings } from '@/components/atoms/solution/Ratings'
import { ClaimStatus } from '@/components/atoms/solution/ClaimStatus'
const CTAButton = lazy(() => import('@/components/atoms/solution/CTAButton'))
const SolutionLogo = lazy(() => import('@/components/molecules/solution/SolutionLogo'))
import SolutionCategories from './SolutionCategories';
import clsx from 'clsx'
import window from 'global'
import { useMobileDetect } from '@/utils/detectMobile'
import { addSolutionUrl } from '@/utils/constants'

type ProfileHeaderProps = {
  company: schema.Company;
  solution: schema.Solution;
  is_claimed: boolean;
  current_user?: schema.User;
  solutionOwner: schema.User;
  logo_url: string;
  handleSubmitClaim: () => void;
  handleContactUs: () => void;
  documents: schema.Document[];
  screenshots: schema.Screenshot[];
  videos: schema.Video[];
  isSolutionOwner: boolean;
  isRmcAdmin: boolean;
  integrations: String[];
  customers: schema.Customer[];
  featuresLength: number;
  promotionsLength: number;
  propositionsLength: number;
  customSolutionCta: schema.CustomSolutionCta
  setHideNav: React.Dispatch<React.SetStateAction<boolean>>
  setHideDropdown: React.Dispatch<React.SetStateAction<boolean>>
  solutionsToCompare?: schema.Solution[]
  setSolutionsToCompare?: React.Dispatch<React.SetStateAction<schema.Solution[]>>
}

const ProfileHeader = ({
  company,
  solution,
  is_claimed,
  current_user,
  solutionOwner,
  logo_url,
  handleSubmitClaim,
  handleContactUs,
  documents,
  screenshots,
  videos,
  isSolutionOwner,
  isRmcAdmin,
  integrations,
  customers,
  featuresLength,
  promotionsLength,
  propositionsLength,
  customSolutionCta,
  setHideNav,
  setHideDropdown,
  solutionsToCompare,
  setSolutionsToCompare,
}: ProfileHeaderProps) => {
  const navbarRef = useRef(null)
  const isMobile = useMobileDetect()

  const [openRequestAQuoteModal, setOpenRequestAQuoteModal] = useState(false)
  const [isEditContactModalOpen, setIsEditContactModalOpen] = useState(false)
  const [isSticky, setIsSticky] = useState(false)
  const [navbarHeight, setNavbarHeight] = useState(0)
  const [isExpanded, setIsExpanded] = useState(false);
  const [isChecked, setIsChecked] = useState(false)

  const tracking = useTracking()

  const handleDemoOnClick = () => {
    tracking.trackEvent({
      event_name: 'solution_custom_cta',
      page_url: window.location.pathname,
      event_loggable_type: "RmcEvents::RmcEvent",
      additional_attributes: { event_type: 'click_event' }
    })
  }

  useEffect(() => {
    const handleScroll = () => {
      setHideNav(false)
      const navbar = navbarRef.current;
      const sticky = isMobile ? navbar.offsetTop - 20 : navbar.offsetTop - 45
      const currentScrollPos = window.scrollY

      if (currentScrollPos + 1 >= sticky) {
        setIsSticky(true)
        setHideNav(true)
        setHideDropdown(true)
      }
    }

    window.addEventListener('scroll', handleScroll)

    if (navbarRef.current) {
      const height = navbarRef.current.getBoundingClientRect().height
      const smallScreen = 768
      const screenHeight = window.innerHeight;
      const newHeight = screenHeight < smallScreen ? height + 15 : height + 30
      setNavbarHeight(newHeight);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  useEffect(() => {
    const solutionNames = solutionsToCompare?.map((s) => {
      return s.name
    })
    if (solutionNames?.includes(solution.name)) {
      setIsChecked(true)
    } else {
      setIsChecked(false)
    }
  }, [solutionsToCompare])

  const handleOnChange = (e) => {
    setIsChecked(e.target.checked)
  }

  const handleCompareSolution = (e) => {
    e.currentTarget.blur()
    let updatedSolutionsToCompare = [...solutionsToCompare]
    if (e.target.checked) {
      setIsChecked(true)
      if (!updatedSolutionsToCompare.includes(solution))
        updatedSolutionsToCompare.push(solution)
      }
      else {
        updatedSolutionsToCompare = updatedSolutionsToCompare.filter((s) => {
          if (s.name === solution.name) return false
          return true
        })
    }
    setSolutionsToCompare(updatedSolutionsToCompare)
  }

  const categoryTags = [...(solution.categories_names || [])].sort();
  const categorySlugs = [...(solution.categories_slugs || [])].sort();

  return (
    <div id='navbar' ref={navbarRef} className={clsx('bg-white', { 'sticky z-20 w-full': isSticky })}>
      <div className='xl:flex xl:w-full xl:justify-center'>
        <div className='flex flex-col gap-2 px-4 md:gap-8 md:px-6 md:py-1 xl:w-[1360px] xl:px-20 mt-2'>
          {!is_claimed && (
            <div className='flex justify-center sm:hidden'>
              <p className="text-xs text-dark-gray tracking-[1px]">Do you work for {solution.name}?<span
                className='cursor-pointer underline text-dark-blue font-bold pl-1'
                onClick={() => window.location.href = addSolutionUrl}
              >
                Claim this solution
              </span>
              </p>
            </div>
          )}

          <div className='flex w-full justify-between bg-white px-2'>
            <div className='flex gap-2 md:gap-8'>
              <div className='flex items-center'>
                <SolutionLogo
                  isClaimed={is_claimed}
                  currentUser={current_user}
                  solutionOwner={solutionOwner}
                  logoUrl={logo_url}
                  solution={solution}
                  company={company}
                />
              </div>
              <div className='flex flex-col gap-2'>
                <div className='flex flex-col lg:flex-row'>
                  <h1 className='w-max text-lg md:text-3xl break-all font-bold pr-2'>
                    {solution?.name}
                  </h1>
                  <div className={`flex flex-row gap-1 xs:items-center`}>
                    <ClaimStatus isClaimed={is_claimed} handleSubmitClaim={handleSubmitClaim} />
                  </div>
                </div>
                <Ratings solution={solution} />
                <div className='hidden flex-wrap gap-2 sm:flex md:max-w-[339px] xl:max-w-[551px]'>
                  <SolutionCategories
                    isExpanded={isExpanded}
                    solution={solution}
                    isMinify={false}
                    categoriesToShow={2}
                    solutionTags={categoryTags}
                    categorySlugs={categorySlugs}
                    setIsExpanded={setIsExpanded}
                  />
                </div>
              </div>
            </div>
            <div className='flex flex-col justify-between gap-2'>
              {openRequestAQuoteModal && (
                <RequestDemoModal
                  openRequestDemoModal={openRequestAQuoteModal}
                  setOpenRequestDemoModal={setOpenRequestAQuoteModal}
                  solution={solution}
                  company={company?.name}
                  redirectURL={solution.website || company.website}
                />
              )}
              {isEditContactModalOpen && (
                <EditContactModal
                  openContactModal={isEditContactModalOpen}
                  setOpenContactModal={setIsEditContactModalOpen}
                  solution={solution}
                />
              )}
              <div className='flex flex-col items-end gap-1 pt-2 sm:pt-0'>
                {!is_claimed &&
                  <div className='hidden sm:flex flex-row justify-end'>
                    <ClaimSolutionButton handleSubmitClaim={handleSubmitClaim} solution={solution} />
                  </div>
                }
                <Suspense fallback={<div></div>}>
                  <CTAButton
                    solution={solution}
                    isSolutionOwner={isSolutionOwner}
                    isRmcAdmin={isRmcAdmin}
                    handleContactUs={handleContactUs}
                    handleDemoOnClick={handleDemoOnClick}
                    customSolutionCta={customSolutionCta}
                    isClaimed={is_claimed}
                  />
                </ Suspense>
              </div>
              <div className='flex items-center gap-1 self-end'>
                <label className='text-xs font-semibold text-medium-gray'>Add To Compare</label>
                <input
                  type='checkbox'
                  onChange={(e) => handleOnChange(e)}
                  className='border-medium-gray h-3 w-3 cursor-pointer rounded-sm checked:bg-dark-blue'
                  onClick={(e) => {
                    e.stopPropagation()
                    handleCompareSolution(e)
                  }}
                  checked={isChecked}
                  disabled={!(solutionsToCompare?.length < 4) && !isChecked}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={'mx-auto px-4 md:px-10 xl:w-[1360px] xl:px-20'}>
        <StickyNavbar solution={solution} isSolutionOwner={isSolutionOwner} isRmcAdmin={isRmcAdmin}
          documents={documents}
          screenshots={screenshots}
          videos={videos}
          integrations={integrations}
          customers={customers}
          featuresLength={featuresLength}
          promotionsLength={promotionsLength}
          propositionsLength={propositionsLength}
          navbarHeight={navbarHeight}
          isClaimed={is_claimed}
        />
      </div>
    </div>
  )
}

export default ProfileHeader
