import React, { useState, useEffect } from 'react';
import { useForm, Link, usePage, router } from '@inertiajs/react';
import Form, { useToken } from '@/components/atoms/forms/Form';
import Layout from '@components/organisms/layouts/Layout';
import Input from '@/components/atoms/inputs/Input';
import Button from '@/components/atoms/buttons/Button';
import ReCaptcha from '@/components/atoms/widgets/Recaptcha';
import Modal from '@/components/atoms/modals/Modal';
import { mixpanel } from '@/utils/mixpanel';
import Meta from '@/components/atoms/widgets/Meta';
import Terms from '@/components/atoms/widgets/Terms';

import { SharedPageProps } from './SignIn';
import ModalChecks from '@/components/molecules/modals/ModalChecks';
import { createJobsNewsletterSubscription, createWeeklyNewsletterSubscription, userRegistration } from '@/utils/axiosRequests';
import { getQueryParamValue } from '@/utils/helperFunctions';

type SignUpPageProps = SharedPageProps & { recaptcha_site_key: string };

const SignUpForm = (props) => {
  const { role } = props;
  const [recaptchaToken, setRecaptchaToken] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [termsAccepted, setTermsAccepted] = useState(true);
  const [responseErrors, setResponseErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState('');
  const [accountCreationFailed, setAccountCreationFailed] = useState(false);
  const [isSubToWeeklyNewsletter, setIsSubToWeeklyNewsletter] = useState(true)
  const [isSubToJobsNewsletter, setIsSubToJobsNewsletter] = useState(true)
  const token = useToken();

  const { flash } = usePage<SignUpPageProps>().props;
  const fallbackUrl = getQueryParamValue('fallbackUrl')

  const { data, setData, errors, transform } = useForm({
    role: role,
    firstName: '',
    lastName: '',
    company: '',
    title: '',
    email: '',
    password: '',
    terms_accepted: termsAccepted,
    g_recaptcha_response: '',
  });

  const clearForm = () => {
    data.firstName = '';
    data.lastName =  '';
    data.email = '';
    data.password = '';
    data.company = '';
    data.title = '';
  };

  const handleSubmitValidation = () => {
    return (
      data.terms_accepted &&
      data.firstName &&
      data.lastName &&
      data.email &&
      data.company &&
      data.password &&
      data.title &&
      recaptchaToken
    );
  };

  transform((data) => {
    return {
      user: { ...data, g_recaptcha_response: recaptchaToken },
      authenticity_token: token,
    };
  });

  const createNewsletterSubscriptions = async (userId) => {
    if(isSubToWeeklyNewsletter)
      await createWeeklyNewsletterSubscription(userId, token)
    if(isSubToJobsNewsletter)
      await createJobsNewsletterSubscription(userId, token)
  }

  async function submit(e) {
    e.preventDefault();
    try{
      const response = await userRegistration(data, token)
      clearForm();
      if (response?.data?.message !== "success") {
        setResponseErrors(response?.data?.message);
        setAccountCreationFailed(true);
      }
      else{
        await createNewsletterSubscriptions(response?.data?.user_id)
        setSuccessMessage('Your request was successfully received, We have sent a confirmation link on your email');
        setAccountCreationFailed(false);
        setTimeout(() => router.visit('/customer/login', {
          data: {
            fallbackUrl: fallbackUrl,
          }
        }),4000);
      }
    }
    catch(error){
      console.log(error);
    }
  }

  useEffect(() => {
    mixpanel.track('Sign Up Start', {
      'Entry Point': 'Sign Up Page',
    });
  }, []);

  return (
    <Layout>
      {Boolean(flash?.notice) && (
        <div
          className="relative mb-4 rounded border border-green-400 bg-teal-tint-2 px-4 py-3 text-green-700"
          role="alert"
        >
          {flash.notice}
        </div>
      )}
      <div className="flex w-full flex-1 flex-col md:max-w-[600px]">
        <div className='mb-4 md:mb-8'>
          <div className="flex flex-col gap-4 md:text-center">
            <p className='text-4xl font-bold text-black lg:text-5xl'>Create your account</p>
            <p>Create your account to gain access to 100s of articles, ebooks, and buyers guides for free.</p>
          </div>
        </div>
        <div className="flex flex-1 flex-col">
          <Form onSubmit={submit}>
            <div>
              <div className="md:flex md:flex-row md:gap-4">
                <Input
                  label="First Name*"
                  labelHtmlFor="firstName"
                  id="firstName"
                  type="text"
                  name="firstName"
                  value={data.firstName}
                  onChange={(e) => setData('firstName', e.target.value)}
                  containerClass="mb-7 w-full"
                  errors={errors.firstName}
                  placeholder='First Name'
                />
                <Input
                  label="Last Name*"
                  labelHtmlFor="lastName"
                  id="lastName"
                  type="text"
                  name="lastName"
                  value={data.lastName}
                  onChange={(e) => setData('lastName', e.target.value)}
                  containerClass="mb-7 w-full"
                  errors={errors.lastName}
                  placeholder='Last Name'
                />
              </div>
              <div className="md:flex md:flex-row md:gap-4">
                <Input
                  label="Company*"
                  labelHtmlFor="company"
                  id="company"
                  type="text"
                  name="company"
                  value={data.company}
                  onChange={(e) => setData('company', e.target.value)}
                  containerClass="mb-7 w-full"
                  errors={errors.company}
                  placeholder='Company'
                />
              </div>
              <div className="md:flex md:flex-row md:gap-4">
                <Input
                  label="Job Title*"
                  labelHtmlFor="Title"
                  id="title"
                  type="text"
                  name="title"
                  value={data.title}
                  onChange={(e) => setData('title', e.target.value)}
                  containerClass="mb-7 w-full"
                  errors={errors.title}
                  placeholder='Job Title'
                />
              </div>
              <div className="w-full">
                <Input
                  label="Work Email*"
                  labelHtmlFor="email"
                  id="email"
                  type="text"
                  name="email"
                  value={data.email}
                  onChange={(e) => setData('email', e.target.value)}
                  containerClass={flash?.errors?.email ? 'mb-2' : 'mb-7'}
                  errors={flash?.errors?.email}
                  placeholder='Work Email'
                />
                {flash?.errors?.email && (
                  <p className="mb-5 text-[13px] text-danger-red">
                    {flash?.errors?.email}
                  </p>
                )}
              </div>
              <Input
                isPassword={true}
                label="Password*"
                labelHtmlFor="password"
                id="password"
                type="password"
                name="password"
                value={data.password}
                onChange={(e) => setData('password', e.target.value)}
                containerClass="mb-7"
                errors={errors.password}
                placeholder='Password'
              />
            </div>

            <div>
              <div className="mb-[30px] flex items-center gap-[10px]">
                <div className='flex flex-col'>{!accountCreationFailed ? (
                    <p className='mb-6 text-dark-blue'>{successMessage}</p>
                  ) : (
                    <div className='flex flex-col text-d mb-6'>
                      {responseErrors.map((error, index) => (
                        <React.Fragment key={index}>
                          <p className='text-danger-red'>{error}</p>
                        </React.Fragment>
                      ))}
                    </div>
                  )}
                  <ModalChecks
                    isAgreeToPrivacyPolicy={termsAccepted}
                    setIsAgreeToPrivacyPolicy={setTermsAccepted}
                    showWeeklyNewsletterCheckbox={true}
                    showJobsNewsletterCheckbox={true}
                    setIsSubToJobsNewsletter={setIsSubToJobsNewsletter}
                    setIsSubToWeeklyNewsletter={setIsSubToWeeklyNewsletter}
                  />
                </div>
              </div>
              <div className="mb-[87px] md:mb-[60px]">
                <ReCaptcha setRecaptchaToken={setRecaptchaToken} />
              </div>
              <div className="flex flex-col items-center">
                <Button
                  type="submit"
                  size="md"
                  variant="primary"
                  disabled={!handleSubmitValidation()}
                  className="w-full md:w-[353px] lg:w-[400px]"
                >
                  Create account
                </Button>
                <div className="mt-5 text-center text-lg">
                  <p>
                    Already a member?{' '}
                    <Link href="/login" className="font-semibold">
                      Log in
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        closeStyle="top-8 right-4 sm:top-[41.5px] sm:right-10 xl:top-8"
        backdropStyle="pt-[60px]"
        className="h-full overflow-scroll rounded-xl px-4 py-8 md:mt-0 md:h-3/4 md:w-[700px] md:px-10 lg:h-4/5 lg:w-[800px]"
      >
        <Terms />
      </Modal>
    </Layout>
  );
};

const SignUpPage = (props) => {
  const { form_path, user } = props;

  const [success, setSuccess] = useState(false);

  return (
    <div>
      <Meta
        title="Sign Up for the Talivity Marketplace - Start
          Connecting with Top Recruitment Marketing SaaS and Service Providers"
        description="Sign up for the Talivity Marketplace and connect with top recruitment marketing SaaS and service providers. Streamline your hiring process and find the best solutions for your business needs."
      />
      <div>
        <div>
          {!success && (
            <SignUpForm
              formPath={form_path}
              role={'customer'}
              setSuccess={setSuccess}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;
