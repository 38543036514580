import { useState } from 'react'
import Modal from '@components/atoms/modals/Modal'
import CropComponent from '@components/molecules/imageAdjustment/CropComponent'
import Button from '@/components/atoms/buttons/Button'

type CropModalProps = {
  openModal: boolean
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>
  src: { name: string }
  handleFileChange: (
    file: File | null,
   ) => void
  previewUrlSetter?: (url: string) => void
  maxImageSize: number
  imageWidth?: number
}

const CropModal = ({
  openModal,
  setOpenModal,
  src,
  handleFileChange,
  previewUrlSetter = () => {},
  maxImageSize,
  imageWidth,
}: CropModalProps) => {
  const [croppedFile, setCroppedFile] = useState(null)

  const handleSave = () => {
    handleFileChange(croppedFile)
  }

  return (
    <Modal
      isOpen={openModal}
      showCloseButton={false}
      onClose={() => {}}
      closeStyle='top-3'
      className='!mx-[20px] min-w-[300px] w-auto gap-5 rounded-xl bg-white px-9 pb-6 pt-12 md:mt-0'
    >
      <h1 className='mt-[-35px] text-2xl font-bold text-gray-600'>Crop your photo</h1>
      <div className='mt-2'>
       <CropComponent src={src} setCroppedFile={setCroppedFile} imageWidth={imageWidth} />
      </div>
      <div className='mt-4 flex flex-row justify-end gap-3'>
      <Button variant='gray' size='md' onClick={() => setOpenModal(false)}> Cancel </Button>
      <Button variant={`${croppedFile? 'teal' : 'disable'}`} size='md' onClick={() => croppedFile? handleSave() : null}> Save</Button>
      </div>
    </Modal>
  )
}

export default CropModal
