import { servicesHeroCards } from '@/components/atoms/constants/OurServicesData'
import ServicesCardValue from '@/components/atoms/ourServices/ServicesCardValue'

function ServicesHero() {
  return (
    <div className="flex font-bold md:max-h-[122px] md:min-h-[122px] lg:max-h-[175px] lg:min-h-[175px] rounded-lg shadow-service-hero items-center justify-center md:top-[61px] lg:top-[87.5px] bg-white mt-11 mb-12">
      {servicesHeroCards.map((card, index) => (
        <div key={index} className="flex text-center items-center justify-center">
          <div className="item-center md:mx-[2.8rem] lg:mx-[2rem] xl:mx-[4.4rem]">
            <p className="font-light md:text-[9.76px] lg:text-[14px]">{card.title}</p>
            <ServicesCardValue card={card}/>
            <p className="font-light md:text-[9.76px] md:leading-[11.85px] lg:text-[14px] lg:leading-[17px]">{card.description}</p>
          </div>
          {index !== servicesHeroCards.length - 1 && <div className="w-[1px] md:h-[63px] lg:h-[89px] bg-[#41CDA7] self-center"></div>}
        </div>
      ))}
    </div>
  )
}

export default ServicesHero
